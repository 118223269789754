import React from "react";

const CycleIcon = ({ className = "", multiTone = false, ...props }) => (
  <svg
    className={"cycle-icon " + className}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 140"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {multiTone
          ? `.cls-1{opacity:0.95;}.cls-2{opacity:0.45;}.cls-3{opacity:0.7;}`
          : ""}
      </style>
    </defs>
    <g className="cls-1">
      <path d="M88.4,74.07H84.19a10.11,10.11,0,0,1,.64,3.54v15a4.61,4.61,0,0,1-.26,1.48h6.8a4.46,4.46,0,0,0,4.46-4.46V81.49A7.43,7.43,0,0,0,88.4,74.07Z" />
      <path d="M54.83,77.61a9.88,9.88,0,0,1,.64-3.54H51.25a7.42,7.42,0,0,0-7.42,7.42v8.12a4.46,4.46,0,0,0,4.45,4.46h6.81a4.35,4.35,0,0,1-.26-1.48Z" />
      <path d="M74.4,70.07H65.25a7.42,7.42,0,0,0-7.42,7.42V92.58a1.49,1.49,0,0,0,1.48,1.49h21a1.49,1.49,0,0,0,1.49-1.49V77.49A7.43,7.43,0,0,0,74.4,70.07Z" />
      <path d="M70,50.37a8.93,8.93,0,0,0-4.36,16.72,8.84,8.84,0,0,0,8.72,0A8.93,8.93,0,0,0,70,50.37Z" />
      <path d="M54.32,58.7a6.68,6.68,0,1,0,5.89,9.81A6.61,6.61,0,0,0,61,65.38,6.69,6.69,0,0,0,54.32,58.7Z" />
      <path d="M85.68,58.7A6.69,6.69,0,0,0,79,65.38a6.71,6.71,0,0,0,6.68,6.68,6.68,6.68,0,1,0,0-13.36Z" />
    </g>
    <path d="M26.27,79.19c-2.72-14.74,7.07-44.73,39.56-48.26v.34a3.67,3.67,0,0,0,5.65,3.15L93.1,20.61a3.51,3.51,0,0,0,1.24-1.27,3.7,3.7,0,0,0-1.22-4.94L71.48.58a3.65,3.65,0,0,0-2-.58,3.7,3.7,0,0,0-3.34,2.12,3.65,3.65,0,0,0-.33,1.6v4C34.27,7.59.71,37.42,2.88,77.65c0,0,4.88-4.74,8.2-4.74C13.88,72.91,14.38,73.46,26.27,79.19Z" />
    <path
      className="cls-2"
      d="M136.9,99.65a3.69,3.69,0,0,0-2.54-2.76l-2.65-.8c7.79-21.8,5.26-59.41-31-80.42a10,10,0,0,1,.25,2,9.43,9.43,0,0,1-4.38,8l-12.5,8c11.07,3.19,36.38,22.92,25.43,55.75l-1.59-.48a3.89,3.89,0,0,0-1.06-.16,3.63,3.63,0,0,0-2.57,1.06,3.68,3.68,0,0,0-1,3.62l7,24.71a3.73,3.73,0,0,0,2.48,2.52,3.77,3.77,0,0,0,1.06.15,3.73,3.73,0,0,0,2.39-.88l19.49-16.73A3.68,3.68,0,0,0,136.9,99.65Z"
    />
    <path
      className="cls-3"
      d="M104.8,119.55l-4.47-15.82C66.88,133.31,39.06,106.08,34,98.26l2.5-1.54A3.75,3.75,0,0,0,38,95a3.68,3.68,0,0,0-1.79-4.75L13.09,79.08a3.69,3.69,0,0,0-1.59-.36,3.67,3.67,0,0,0-3.67,3.62L7.43,108A3.67,3.67,0,0,0,13,111.2l1.24-.76c21.21,34.4,69.89,37.78,96.24,15.31A9.42,9.42,0,0,1,104.8,119.55Z"
    />
  </svg>
);

export default CycleIcon;
