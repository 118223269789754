import React from "react";
import { NavLink } from "react-router-dom";
import PhoenixLogo from "../custom/PhoenixLogo";
import {
  BsCartFill,
  BsMegaphoneFill,
  BsSpeedometer,
  BsTable,
} from "react-icons/bs";
import { TfiCommentsSmiley } from "react-icons/tfi";
import QSIcon from "../custom/QSIcon";
import { FaStore } from "react-icons/fa6";
import { useDrawer } from "../../context/DrawerContext";
import CycleIcon from "../custom/CycleIcon";

const SideMenu = () => {
  const { showMenu, setShowMenu, handleShowOffCanvas } = useDrawer();
  const handleClick = () => {
    if (window?.innerWidth < 1200) {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  };
  const handleToggle = () => {
    setShowMenu((curr) => !curr);
  };

  return (
    <>
      <div
        className={`nav-backdrop ${showMenu ? "isOpen" : ""} `}
        onClick={() => setShowMenu(false)}
      />
      <nav
        className={`side-menu ${showMenu ? "isOpen" : ""} `}
        data-bs-theme="dark"
      >
        <div>
          <span className="logo" onClick={handleToggle}>
            <PhoenixLogo className="hover-icon" />
          </span>
          <div className="nav-inner colored-links" onClick={handleClick}>
            <NavLink to="/" title="Dashboard" className="dashboard">
              <BsSpeedometer size={20} />{" "}
              <span className="link-text">Dashboard</span>
            </NavLink>
            <NavLink to="/customers" title="Customers" className="customer">
              <FaStore /> <span className="link-text">Customers</span>
            </NavLink>
            <NavLink to="/orders" title="Order History" className="order">
              <BsCartFill /> <span className="link-text">Orders</span>
            </NavLink>
            <NavLink to="/variants" title="Variants" className="variant">
              <FaStore /> <span className="link-text">Variants</span>{" "}
            </NavLink>
            <NavLink to="/programs/campaigns" className="campaign">
              <BsMegaphoneFill /> <span className="link-text">Campaigns</span>
            </NavLink>
            <NavLink to="/programs/cycles" className="cycle">
              <CycleIcon /> <span className="link-text">Loyalty cycles</span>
            </NavLink>
            {/* <NavLink  to="/treatments">
              <BsJournalBookmarkFill />{" "}
              <span className="link-text">Treatments</span>
            </NavLink> */}
            <NavLink to="/reports/actionlist" className="actionList">
              <BsTable size={19} />{" "}
              <span className="link-text">Action lists</span>
            </NavLink>
            <NavLink to="/reports/quicksight" className="quickSight">
              <QSIcon size={18} /> <span className="link-text">QuickSight</span>
            </NavLink>
          </div>
        </div>
        <div className="nav-inner no-b-t">
          {/* <button className="btn-link no-b-b" onClick={handleShowOffCanvas}>
            <BsFillQuestionCircleFill />{" "}
            <span className="link-text">Help</span>
          </button> */}
          <button className="btn-link no-b-b" onClick={handleShowOffCanvas}>
            <TfiCommentsSmiley /> <span className="link-text">Feedback</span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default SideMenu;
