import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import useBreadcrumbs from "../../utils/useBreadcrumbs";

const Breadcrumbs = ({
  showIcons = false,
  showActive = false,
  showRootOnly = false,
  breadcrumbsLabel,
  path,
  pageTitle,
  className = "",
}) => {
  const { breadcrumbs = [] } = useBreadcrumbs({ path, pageTitle });

  if (
    breadcrumbs?.length === 0 ||
    (breadcrumbs?.length === 1 && !showActive) ||
    (breadcrumbs?.length <= 2 && !showActive && !showRootOnly)
  ) {
    return null;
  }

  return (
    <div className={`d-flex flex-row breadcrumb-wrap ${className}`}>
      <Breadcrumb className="delay-parent" data-bs-theme="dark">
        {!!breadcrumbs &&
          breadcrumbs.map(({ url = "", title, icon: Icon }, i) => (
            <LinkContainer to={url} key={`bclink${i}${url}`}>
              <Breadcrumb.Item active={!url} className="delay fade-in-right">
                <div className="item-inner">
                  {!showActive && i === breadcrumbs.length - 1 ? null : (
                    <>
                      {showIcons && Icon ? <Icon /> : null}
                      <span className="page-title">{title}</span>
                    </>
                  )}
                </div>
              </Breadcrumb.Item>
            </LinkContainer>
          ))}
      </Breadcrumb>
      {breadcrumbsLabel}
    </div>
  );
};

export default Breadcrumbs;
