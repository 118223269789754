import React from "react";
import Layout from "../layout/Layout";
import Error from "../ui/Error";

const PageNotFound = () => {
  return (
    <Layout pageTitle="Page not found">
      <div
        className="d-flex align-items-center justify-cotnent-center"
        style={{ minHeight: "calc(100vh - var(--header-height))" }}
      >
        <Error error="404" />
      </div>
    </Layout>
  );
};

export default PageNotFound;
