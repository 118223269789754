import React from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronLeft,
  CgPushChevronRight,
} from "react-icons/cg";

const PaginationALDetail = ({ viewData, page, setPage, dataIsValidating }) => {
  const onNext = () => setPage((curr) => curr + 1);
  const onPrev = () => setPage((curr) => curr - 1);
  const totalPages = Math.round(viewData?.total / viewData?.pageSize);

  return (
    <>
      <div>
        {viewData ? (
          <>
            {viewData && viewData?.pageSize * (page - 1) + 1} -{" "}
            {viewData && viewData?.pageSize * page}
          </>
        ) : null}
      </div>
      <div className="d-wrap justify-content-center hstack gap-1">
        <Button
          variant={"outline-secondary"}
          disabled={!viewData || page === 1}
          onClick={() => setPage(1)}
          title={`${page && page > 1 ? 1 : ""}`}
          label="First"
          size={"sm"}
        >
          <CgPushChevronLeft className="btn-icon" />
        </Button>
        <Button
          variant={"outline-secondary"}
          disabled={!viewData || page === 1}
          onClick={() => onPrev()}
          title={`${page && page > 1 ? page - 1 : ""}`}
          label="Previous"
          size={"sm"}
        >
          <CgChevronLeft className="btn-icon" />
        </Button>
        {totalPages ? (
          <span className="px-2 cursor-default user-select-none">
            {page} <span className="text-muted">of</span> {totalPages}
          </span>
        ) : (
          <span className="text-center" style={{ minWidth: "80px" }}>
            {dataIsValidating ? (
              <Spinner size="sm" variant="secondary" className="opacity-50" />
            ) : (
              <span className="px-2 cursor-default user-select-none"></span>
            )}
          </span>
        )}
        <Button
          variant={"outline-secondary"}
          disabled={!viewData || page >= totalPages}
          onClick={() => onNext()}
          title={`${page && page < totalPages ? page + 1 : ""}`}
          label="Next"
          size={"sm"}
        >
          <CgChevronRight className="btn-icon" />
        </Button>
        <Button
          variant={"outline-secondary"}
          disabled={!viewData || page >= totalPages}
          onClick={() => setPage(totalPages)}
          title={`${page && page < totalPages ? totalPages : ""}`}
          label="Last"
          size={"sm"}
        >
          <CgPushChevronRight className="btn-icon" />
        </Button>
      </div>
      <div> {viewData ? <>{viewData?.total} results</> : null}</div>
    </>
  );
};

export default PaginationALDetail;
