import React from "react";
import ListPage from "../ui/ListPage";
import { config } from "../../config";

const Products = () => {
  return (
    <ListPage
      baseUrl={`${config.api.order}/products`}
      itemLabel="product"
      itemPath="products"
      tableItems={{
        thumbnailFileId: {},
        id: {},
        name: {},
        code: {
          cellClass: "text-start",
        },
        programId: {
          displayHeading: "Program",
        },
        mailMomentId: {
          displayHeading: "Mailmoment",
        },
        segmentId: {
          displayHeading: "Segment",
        },
        treatmentId: {
          displayHeading: "Treatment",
        },
        minAmount: {
          displayHeading: "Min. amount",
        },
        stepSize: {
          displayHeading: "Step size",
        },
        price: {},
        description: {},
      }}
      searchParam="searchPhrase"
      orderOptions={["id", "sequence"]}
      defaultLayout={"grid-xl"}
    />
  );
};

export default Products;
