import React from "react";
import { Badge } from "react-bootstrap";

const StatusBadge = ({
  value: status,
  effectClass = "fade-in delay",
  className,
  ...props
}) => {
  return (
    <Badge
      className={`font-monospace mw-5 text-center ${
        status === "unknown" || status === "final"
          ? "bg-secondary-tint-25"
          : status === "open"
          ? "bg-success-tint-25"
          : status === "notOpened"
          ? "bg-px-hl"
          : status === "closed"
          ? "bg-danger-tint-25"
          : status === "new"
          ? "bg-warning-shade-10"
          : status === "pending"
          ? "bg-info-shade-10"
          : ""
      } ${effectClass} ${className}`}
      pill
      {...props}
    >
      {status}
    </Badge>
  );
};

export default StatusBadge;
