import React, { useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  ProgressBar,
  Spinner,
  Stack,
} from "react-bootstrap";
import fetcher from "../../../utils/fetcher";
import { config } from "../../../config";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { BsCaretDownFill, BsCaretRight, BsCheck2All } from "react-icons/bs";
import { FaDownload, FaFileCsv, FaFileExcel, FaUserTie } from "react-icons/fa6";
import SearchBox from "../../ui/SearchBox";
import ImgBox from "../../ui/ImgBox";
import createDetailedErrorMessage from "../../../utils/detailMessageError";

const HeaderALDetail = ({
  isViewExpanded,
  selectedRows,
  actionOption,
  actionOptions,
  refreshViewData,
  setSelectedRows,
  setPage,
  showSelectCustomer,
  setShowSelectCustomer,
  view,
  query,
  setQuery,
  setIsViewExpanded,
  setActionOption,
}) => {
  const { reportId } = useParams();
  const { client, setToasts } = useAuthContext();
  const ROW_LIMIT = 50;
  const selectedRowCount = selectedRows.length;
  const [isDownloading, setIsDownloading] = useState(false);
  const ESTIMATED_TIME_PER_ACTION = 100; //100 actually better for test data but should maybe become 500
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  /*
    Handles logic for applying an action based on the given action id.
  */
  const applyActionById = (actionId) => {
    startProgress();
    setActionInProgress(true);
    fetcher({
      url: `${config.api.reporting}/views/${reportId}/actions/${actionId}`,
      method: "POST",
      customerId: client?.customer?.id,
      clientId: client?.id,
      payload: selectedRows.map((rowId) => ({
        rowId: "" + rowId,
        optionKey:
          !!actionOption && !!actionOption.value ? actionOptions.value : null,
      })),
    })
      .then(() => {
        clearProgress();
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Success!",
            delay: 2000,
            text: "Action successfully applied",
          },
        ]);
        refreshViewData();
        setSelectedRows([]);
        setPage(1);
      })
      .catch((error) => {
        clearProgress();
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "danger",
            heading: "Failed to apply action",
            delay: 50000,
            text: createDetailedErrorMessage(error),
          },
        ]);
      });
  };

  //tog
  const toggleShowSelectorCustomer = () => {
    setShowSelectCustomer(!showSelectCustomer);
  };

  const downloadFile = (exportType) => {
    if (exportType === "csv" || exportType === "excel") {
      setIsDownloading(true);
      fetcher({
        url: `${config.api.reporting}/views/${reportId}/data/file?fileType=${exportType}`,
        method: "GET",
        customerId: client?.customer?.id,
        clientId: client?.id,
      })
        .then((res) => {
          setIsDownloading(false);
          try {
            const a = document.createElement("a");
            a.href = `data:${res?.data?.mimeType};base64,${res?.data?.data}`;
            a.download = view?.name;
            a.click();
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "success",
                heading: "Success!",
                delay: 2000,
                text: "File succesfully downloaded",
              },
            ]);
          } catch (error) {
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "danger",
                heading: "Error while saving the file",
                delay: 50000,
                text: createDetailedErrorMessage(error),
              },
            ]);
          }
        })
        .catch((error) => {
          setIsDownloading(false);
          setToasts((currToasts) => [
            ...currToasts,
            {
              id: Date.now(),
              variant: "danger",
              heading: "Error while downloading the file",
              text: createDetailedErrorMessage(error),
            },
          ]);
        });
    }
  };

  /*
    Starts the logic of the progress of handling an action. 
    This progress is displayed with a progress bar and gives the user
    information about the action's estimated progress
  */
  const startProgress = () => {
    // Clear any existing interval
    if (intervalId) clearInterval(intervalId);
    setProgress(0);

    // Calculate the total time and increment per update
    const totalTime = selectedRows?.length * ESTIMATED_TIME_PER_ACTION;
    const increment = (100 / totalTime) * 100;

    // Set up a new interval
    const id = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + increment;
        if (nextProgress >= 100) {
          clearInterval(id);
          return 100; // Make sure not to exceed 100%
        }
        return nextProgress;
      });
    }, 100); // Update progress every 100ms

    // Store the interval ID so we can clear it later
    setIntervalId(id);
  };

  const clearProgress = () => {
    clearInterval(intervalId);
    setProgress(0);
    setIntervalId(null);
    setActionInProgress(false);
  };

  return (
    <Stack aria-label="Toolbar" direction="horizontal" gap={2}>
      {/* Actions */}
      {isViewExpanded ? null : (
        <Button
          title="Open sidemenu"
          className={"position-relative"}
          onClick={() => setIsViewExpanded(!isViewExpanded)}
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
          }}
        >
          <BsCaretRight
            style={{
              color: "rgba(0, 0, 0, 0.6)",
            }}
          />
        </Button>
      )}
      {/*Dropdown for action options*/}
      {!!actionOptions && actionOptions.length > 0 ? (
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            style={{
              minWidth: "200px",
              fontSize: "12.5px",
            }}
          >
            {actionOption ? actionOption.label : ""} <BsCaretDownFill />
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              maxHeight: "350px",
              overflowY: "scroll",
            }}
          >
            {actionOptions.map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() => setActionOption(item)}
                style={
                  actionOption && item.value === actionOption.value
                    ? { backgroundColor: "rgba(0, 123, 170, 0.2)" }
                    : {}
                }
              >
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
      {/* Button to apply all available actions */}
      {!!view?.chart?.actions?.length
        ? view.chart.actions.map((a) => (
            <Button
              key={a?.id}
              size="sm"
              title={a?.description || null}
              disabled={!selectedRows?.length}
              variant="success"
              onClick={() => applyActionById(a.id)}
            >
              <BsCheck2All className="ms-1 me-2" />
              {a?.name}

              {/* Row selection count */}
              <Badge
                className={`cursor-default font-monospace ms-2 ${
                  selectedRowCount >= ROW_LIMIT
                    ? "bg-danger bg-opacity-50"
                    : selectedRowCount >= ROW_LIMIT - 2
                    ? "bg-warning bg-opacity-75"
                    : "bg-dark bg-opacity-10"
                }`}
                title={
                  selectedRowCount >= ROW_LIMIT
                    ? `Row limit reached`
                    : selectedRowCount >= ROW_LIMIT - 2
                    ? `Max ${ROW_LIMIT} rows`
                    : `${selectedRowCount || "No"} ${
                        selectedRowCount === 1 ? "row" : "rows"
                      } selected`
                }
              >
                <span
                  className="d-inline-block text-center"
                  style={{ minWidth: "1.25em" }}
                >
                  {selectedRowCount}
                </span>
              </Badge>
            </Button>
          ))
        : null}

      <div
        style={{
          marginRight: "75px",
        }}
      >
        {/*Progressbar that shows estimated progress of an action*/}
        {actionInProgress && (
          <ProgressBar
            now={progress}
            label={progress > 15 ? `Progress : ${progress.toFixed(0)}%` : null}
            striped
            variant="success"
            style={{
              color: "white",
              borderRadius: "5px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              width: "250px",
            }}
          />
        )}
      </div>
      <div className="ms-auto" />
      {/* Export CSV / Excel  */}
      <Dropdown onSelect={(item) => downloadFile(item)}>
        <Dropdown.Toggle
          variant="secondary"
          title={isDownloading ? "Preparing your file..." : "Export"}
          disabled={isDownloading || !!selectedRowCount}
          size="sm"
        >
          <span className="d-inline-block me-1" style={{ minWidth: "24px" }}>
            {isDownloading ? <Spinner size="sm" /> : <FaDownload />}
          </span>
          Download{" "}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="csv" className="dropdown-item">
            <FaFileCsv className="text-muted me-1" /> CSV{" "}
            <span className="text-muted font-monospace">.csv</span>
          </Dropdown.Item>
          <Dropdown.Item eventKey="excel" className="dropdown-item">
            <FaFileExcel className="text-muted me-1" /> Excel{" "}
            <span className="text-muted font-monospace">.xlsx</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* Search - hide if no searchable columns */}
      {view?.chart?.columns?.some((col) => !!col?.searchable) ? (
        <SearchBox
          className="w-auto"
          query={query}
          setQuery={setQuery}
          size="sm"
        />
      ) : null}
      {/* Customer */}
      {client?.customer ? (
        <Button
          size="sm"
          variant="link-dark text-decoration-none"
          onClick={toggleShowSelectorCustomer}
          className="px-0"
        >
          <ImgBox
            imgId={client?.customer?.logoFileId}
            width={29}
            icon={FaUserTie}
            className="d-inline-block border"
            style={{
              width: "29px",
              height: "29px",
              marginTop: "-9px",
              marginBottom: "-9px",
              borderRadius: "3px",
            }}
          />
          <span className="fw-semibold px-2">{client?.customer?.name}</span>
        </Button>
      ) : null}
    </Stack>
  );
};

export default HeaderALDetail;
