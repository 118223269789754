import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsCheck2, BsTrash, BsX } from "react-icons/bs";

const BtnWithDialog = ({
  children = <div className="py-3">Are you sure?</div>,
  modalSize = "sm",
  title,
  triggerEl: Trigger = Button,
  onTrigger,
  onConfirm,
  onCancel,
  isDisabled,
  icon: Icon = BsTrash,
  btnText = "Delete",
  variant = "danger",
  btnSize,
}) => {
  const [show, setShow] = useState(false);

  const handleTrigger = () => {
    onTrigger && onTrigger();
    setShow(true);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setShow(false);
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setShow(false);
  };

  return (
    <>
      <Trigger
        variant={variant}
        size={btnSize}
        disabled={isDisabled}
        onClick={handleTrigger}
      >
        <Icon className="me-1" /> {btnText}
      </Trigger>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        fullscreen="mo-down"
        scrollable
        size={modalSize}
      >
        {title ? (
          <Modal.Header closeButton className="py-1">
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body className="bg-light px-4">{children}</Modal.Body>
        <Modal.Footer className="p-1 border-0 justify-content-evenly flex-nowrap">
          <Button
            variant="outline-secondary"
            onClick={handleCancel}
            className="w-100"
          >
            <BsX className="me-1" /> No
          </Button>
          <Button variant="danger" onClick={handleConfirm} className="w-100">
            <BsCheck2 className="me-1" /> Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BtnWithDialog;
