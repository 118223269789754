export const helpSection = [
  {
    id: "123456",
    title: "Marketing name",
    text: "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p>",
    parentCategoryId: "123",
  },
  {
    id: "223456",
    title: "Order type",
    text: "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p><ul><li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li><li>Aliquam tincidunt mauris eu risus.</li><li>Vestibulum auctor dapibus neque.</li></ul>",
    parentCategoryId: "223",
  },
  {
    id: "323456",
    title: "Default profile",
    text: "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p><ul><li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li><li>Aliquam tincidunt mauris eu risus.</li><li>Vestibulum auctor dapibus neque.</li></ul>",
    parentCategoryId: "323",
  },
  {
    id: "423456",
    title: "Customer",
    text: "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p><ul><li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li><li>Aliquam tincidunt mauris eu risus.</li><li>Vestibulum auctor dapibus neque.</li></ul>",
    parentCategoryId: "323",
  },
];

export const helpSectionCategories = [
  {
    title: "Program",
    id: "323",
    description:
      "This is a short plain-text description of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: null,
  },
  {
    title: "Customer",
    id: "123",
    description:
      "This is a short plain-text description  of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: null,
  },
  {
    title: "Marketing profile",
    id: "1231",
    description:
      "This is a short plain-text description  of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: 123,
  },
  {
    title: "Customer addresses",
    id: "2231",
    description:
      "This is a short plain-text description  of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: 123,
  },
  {
    title: "Order",
    id: "223",
    description:
      "This is a short plain-text description  of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: null,
  },
  {
    title: "Proofing",
    id: "423",
    description:
      "This is a short plain-text description of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: null,
  },
  {
    title: "Reporting",
    id: "523",
    description:
      "This is a short plain-text description of what should be placed in this category. nobis amet totam earum temporibus delectus culpa similique?",
    parentCategoryId: null,
  },
];
