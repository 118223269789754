import React from "react";
import { Button } from "react-bootstrap";
import {
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronLeft,
  CgPushChevronRight,
} from "react-icons/cg";

const PaginationButtons = ({
  pageSize: PAGE_SIZE,
  page,
  setPage,
  isLoading,
}) => {
  const onNext = () => setPage((curr) => curr + 1);
  const onPrev = () => setPage((curr) => curr - 1);
  return (
    <div className="d-wrap justify-content-center hstack gap-1 w-100">
      <Button
        variant={"outline-secondary"}
        disabled={isLoading || page === 1}
        onClick={() => setPage(1)}
        title={`${page && page > 1 ? `To page 1` : ""}`}
        label="First"
        size={"sm"}
      >
        <CgPushChevronLeft className="btn-icon" />
      </Button>
      <Button
        variant={"outline-secondary"}
        disabled={isLoading || page === 1}
        onClick={() => onPrev()}
        title={`${page && page > 1 ? `To page ${page - 1}` : ""}`}
        label="Previous"
        size={"sm"}
      >
        <CgChevronLeft className="btn-icon" />
      </Button>
      {PAGE_SIZE ? (
        <span className="px-2 cursor-default user-select-none">
          {page} <span className="text-muted">of</span> {PAGE_SIZE}
        </span>
      ) : (
        <span className="text-center" style={{ minWidth: "80px" }}></span>
      )}
      <Button
        variant={"outline-secondary"}
        disabled={isLoading || page === PAGE_SIZE}
        onClick={() => onNext()}
        title={`${page && page < PAGE_SIZE ? `To page ${page + 1}` : ""}`}
        label="Next"
        size={"sm"}
      >
        <CgChevronRight className="btn-icon" />
      </Button>
      <Button
        variant={"outline-secondary"}
        disabled={isLoading || page === PAGE_SIZE}
        onClick={() => setPage(PAGE_SIZE)}
        title={`${page && page < PAGE_SIZE ? `To page ${PAGE_SIZE}` : ""}`}
        label="Last"
        size={"sm"}
      >
        <CgPushChevronRight className="btn-icon" />
      </Button>
    </div>
  );
};

export default PaginationButtons;
