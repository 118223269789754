import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import { useAuthContext } from "../../../context/AuthContext";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import fetcher from "../../../utils/fetcher";
import { config } from "../../../config";
import { useParams } from "react-router-dom";
import suprise from "../../../assets/il-surprise.svg";

const ParamField = ({
  editable,
  setEditable,
  register,
  setValue,
  dirtyFields,
  index,
  watch,
  displayName,
  inheritedValue,
  valueOptions,
  type,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onChange = () => {
    if (!watch(`${index}.isInherited`)) {
      setValue(`${index}.parameterValue`, null, {
        shouldDirty: true,
      });
      setValue(
        `${index}.defaultOption`,
        valueOptions.find((option) => option.isDefault === true)?.value
      );
      setValue(`${index}.isInherited`, true);
    } else {
      setValue(`${index}.parameterValue`, inheritedValue, {
        shouldDirty: true,
      });

      setValue(`${index}.isInherited`, false);
    }
    setEditable(true);
  };

  return (
    <tr className="d-flex justify-content-center">
      <td style={{ width: "50%", paddingLeft: "30px" }}>{displayName}</td>
      <td className="w-100" style={{ paddingLeft: "50px" }}>
        <div className="position-relative w-100">
          {isFocused && (
            <Badge
              className="font-monospace position-absolute top-0  translate-middle-y z-index-1 bg-primary-tint-50 me-2 fs-65"
              bg={null}
            >
              {watch(`${index}.displayName`)}
            </Badge>
          )}
          {!watch(`${index}.isInherited`) ? (
            <Form.Control
              onClick={() => !editable && setEditable(true)}
              as="textarea"
              {...register(`${index}.parameterValue`)}
              placeholder={inheritedValue}
              style={
                dirtyFields[index]?.parameterValue
                  ? {
                      width: "500px",
                      backgroundColor: "rgba(0, 255, 0, 0.2)",
                    }
                  : { width: "500px" }
              }
              onFocus={(e) => {
                if (!e.target.value) {
                  setValue(`${index}.parameterValue`, inheritedValue);
                }
                setIsFocused(true);
              }}
              onBlur={() => setIsFocused(false)}
              rows={3}
            />
          ) : (
            <Form.Control
              onClick={() => !editable && setEditable(true)}
              as="textarea"
              style={
                dirtyFields[index]?.parameterValue
                  ? {
                      width: "500px",
                      backgroundColor: "rgba(0, 255, 0, 0.2)",
                    }
                  : { width: "500px" }
              }
              value={inheritedValue || ""}
              disabled={true}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              rows={3}
            />
          )}
        </div>
      </td>
      <td style={{ width: "40%" }}>
        {valueOptions && valueOptions.length > 0 ? (
          <Form.Select
            onClick={() => !editable && setEditable(true)}
            value={watch(`${index}.defaultOption`)}
            onChange={(e) => {
              setEditable(true);
              setValue(`${index}.parameterValue`, e.target.value, {
                shouldDirty: true,
              });
              setValue(`${index}.defaultOption`, e.target.displayName);
            }}
            disabled={watch(`${index}.isInherited`)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={{ width: "200px" }}
          >
            {valueOptions.map((option, i) => (
              <option key={option.sequence} value={option.value}>
                {option.displayName}
              </option>
            ))}
          </Form.Select>
        ) : null}
      </td>
      <td style={{ width: "40%" }}>
        <Form.Check
          type="switch"
          label="Is inherited"
          onChange={onChange}
          checked={watch(`${index}.isInherited`) === true}
        />
      </td>
    </tr>
  );
};

const VariantParameters = ({ parameters, refreshParameters }) => {
  const { setToasts, client } = useAuthContext();
  const { variantId, customerId } = useParams();

  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState(null);
  const values = parameters
    ? parameters.map((param) => ({
        name: param.name,
        inheritedValue: param.inheritedValue,
        parameterValue: param.parameterValue,
        isInherited: param.parameterValue === null ? true : false,
        valueOptions: param.valueOptions,
        defaultOption:
          param.parameterValue ||
          param.valueOptions.find((option) => option.isDefault === true)?.value,
      }))
    : [];

  const defaultValues = values ? Object.assign({}, values) : [];
  const [editable, setEditable] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    setEditable(false);
    setIsBusy(true);

    const payload = Object.values(data).map((param) => ({
      name: param.name,
      value: param.parameterValue,
    }));

    fetcher({
      url: `${config.api.phoenix}/variants/${variantId}/parameters`,
      method: "PUT",
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refreshParameters();
        reset(data);
        setIsBusy(false);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Done",
            delay: 10000,
            text: "Parameters updated successfully",
            img: suprise,
          },
        ]);
      })
      .catch((err) => {
        setError(err);
        setIsBusy(false);
        reset();
      });
  };

  const onCancel = () => {
    setEditable(false);
    reset(defaultValues);
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to upload or submit image",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      {!!parameters && !!parameters.length ? (
        <Card>
          <Table
            responsive
            hover
            striped
            className="mb-0 variant-parameters-table"
          >
            <tbody>
              {parameters.map((item, index) => (
                <ParamField
                  key={item.name}
                  editable={editable}
                  setEditable={setEditable}
                  setValue={setValue}
                  register={register}
                  dirtyFields={dirtyFields}
                  index={index}
                  watch={watch}
                  displayName={item.displayName}
                  parameterValue={item.parameterValue}
                  inheritedValue={item.inheritedValue}
                  valueOptions={watch(`${index}.valueOptions`)}
                  type={item.type}
                />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="sticky-bottom bg-gray-200 d-flex justify-content-end align-items-center">
            {editable ? (
              <>
                <Button
                  variant="secondary"
                  disabled={isBusy}
                  onClick={onCancel}
                  className="me-2 mw-8"
                >
                  <BsX className="btn-icon me-1" />
                  Cancel
                </Button>
                <Button
                  variant="success"
                  disabled={isBusy || !isDirty}
                  type="submit"
                  className="mw-8"
                >
                  <BsCheck2 className="btn-icon me-1" /> Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="success"
                  disabled={isBusy}
                  onClick={() => setEditable(true)}
                  className="mw-8"
                >
                  <BsPencil className="btn-icon me-1" /> Edit
                </Button>
              </>
            )}
          </Card.Footer>
        </Card>
      ) : null}
    </Form>
  );
};

export default VariantParameters;

/*
This a custom component that would have rezised the height of the textarea based on the content
This is left commented so that we can take inspiration from it later on


const AutoResizingTextarea = ({ register, index, value }) => {
  const textareaRef = useRef(null);

  // Adjust height based on content
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height based on content
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height on initial load
  }, [value]); // Ensure it runs if value changes

  return (
    <Form.Control
      as="textarea"
      {...register(`${index}.inheritedValue`, { maxLength: 500 })}
      ref={textareaRef}
      defaultValue={value} // Set the initial value
      onInput={adjustHeight} // Adjust height on user input
      style={{ overflow: "hidden", width: "400px" }} // Hide the scrollbar
    />
  );
};*/
