import React from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { config } from "../../../config";
import useFetch from "../../../utils/useFetch";
import { useParams } from "react-router-dom";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getImgUrl } from "../../../utils/getImgUrl";
import Image from "react-bootstrap/Image";
import { useSWRConfig } from "swr";
import ProgramDetailForm from "./ProgramDetailForm";

const ProgramDetail = () => {
  const { programId } = useParams();

  const {
    data: campaign,
    error,
    key: campaignKey,
  } = useFetch({
    url: `${config.api.phoenix}/programs/${programId}`,
    customerId: config.auth.testCustomer,
  });

  const { mutate } = useSWRConfig();

  return (
    <Layout
      pageTitle={
        campaign.marketingName || campaign.name || "Campaign " + campaign.id
      }
      path={[{ url: "/programs", title: "Programs" }]}
      menuType={campaign.name}
    >
      <WithLoaderAndError
        isLoading={!campaign && !error}
        error={error}
        fullpage
      >
        {campaign ? (
          <>
            <div className="dashboard-wrap mt-n5">
              <Tabs defaultActiveKey="general" variant="pills" className="mb-1">
                <Tab eventKey="general" title="General">
                  <Card body>
                    <Row className="g-3">
                      <Col lg={3}>
                        <WithLoaderAndError
                          isLoading={!campaign && !error}
                          error={error}
                        >
                          <Image
                            fluid
                            src={getImgUrl(campaign.thumbnailFileId, {
                              width: 400,
                              height: 400,
                            })}
                          />
                          <Card body className="bg-dark bg-opacity-10 my-3">
                            <h4>Status</h4>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="Active"
                              defaultChecked
                            />
                            <hr />
                            <Form.Group controlId="programStatus">
                              <Form.Label hidden>Status</Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option value="2">Open for orders</option>
                                <option value="0">To be activated</option>
                                <option value="1">Closed</option>
                              </Form.Select>
                            </Form.Group>
                          </Card>
                        </WithLoaderAndError>
                      </Col>
                      <Col lg={9}>
                        <ProgramDetailForm
                          campaign={campaign}
                          refetch={() => mutate(campaignKey)}
                        />
                        <Row className="g-3">
                          <Col sm={12}>
                            <Card body className="bg-dark bg-opacity-10">
                              <h4>Drop date schedule</h4>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Always orderable"
                                defaultChecked={false}
                              />

                              <hr />
                              <Table
                                striped
                                bordered
                                hover
                                style={{ marginBottom: 0 }}
                              >
                                <thead>
                                  <tr>
                                    <th>Doormat date start</th>
                                    <th>Doormat date end</th>
                                    <th>Order deadline</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {campaign.dropDateSchedules.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.dropDateStart}</td>
                                      <td>{item.dropDateEnd}</td>
                                      <td>{item.orderDeadline}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Tab>

                <Tab eventKey="images" title="Images">
                  Images
                </Tab>
                <Tab eventKey="planning" title="Planning">
                  Planning
                </Tab>
                <Tab eventKey="manage-products" title="Manage products">
                  <div className="base-bg r-pad">Products</div>
                </Tab>
                <Tab eventKey="manage-packages" title="Manage packages">
                  <div className="base-bg r-pad">Packages</div>
                </Tab>
                <Tab eventKey="profiles" title="Profiles">
                  <div className="base-bg r-pad">Profiles</div>
                </Tab>
                <Tab eventKey="customers" title="Customers">
                  <div className="base-bg r-pad">Customers</div>
                </Tab>
                <Tab eventKey="allowed-customers " title="Allowed customers">
                  <div className="base-bg r-pad">Allowed customers</div>
                </Tab>
                <Tab eventKey="mailmoments" title="Mailmoments">
                  <div className="base-bg r-pad">Mailmoments</div>
                </Tab>
                <Tab eventKey="order-history" title="Order history">
                  <div className="base-bg r-pad">blabOrder historylabla</div>
                </Tab>
                <Tab eventKey="extra" title="Extra">
                  <div className="base-bg r-pad">Extra</div>
                </Tab>
                <Tab eventKey="filters" title="Filters">
                  <div className="base-bg r-pad">Filters</div>
                </Tab>
                <Tab eventKey="shot-planning" title="Shot planning">
                  <div className="base-bg r-pad">Shot planning</div>
                </Tab>
                <Tab eventKey="order-settings" title="Order settings">
                  <div className="base-bg r-pad">Order settings</div>
                </Tab>
              </Tabs>
            </div>
          </>
        ) : null}
      </WithLoaderAndError>
    </Layout>
  );
};

export default ProgramDetail;
