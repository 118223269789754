import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext";
import FormComponent from "../../../ui/FormComponent";
import { config } from "../../../../config";
import fetcher from "../../../../utils/fetcher";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";

const CustomerSuppliersForm = ({
  formData,
  onSucces,
  suppliersTypes,
  contactTypes,
}) => {
  const { customerId } = useParams();
  const { setToasts, client } = useAuthContext();
  const [showModal, setShowModal] = useState(null);
  const [error, setError] = useState(null);
  const method = !!formData ? "PUT" : "POST";
  const id = !!formData ? formData.id : null;
  const dataUrl = `${config.api.phoenix}/suppliers${!!id ? "/" + id : ""}`;

  const [contacts, setContacts] = useState(
    !!formData && !!formData.contactDetails && !!formData.contactDetails.length
      ? formData.contactDetails.map((item, index) => index)
      : [0]
  );

  const [addresses, setAddresses] = useState(
    !!formData && !!formData.addresses && !!formData.addresses.length
      ? formData.addresses.map((item, index) => index)
      : [0]
  );

  const addContact = () =>
    setContacts([
      ...contacts,
      contacts.length > 0 ? contacts[contacts.length - 1] + 1 : 0,
    ]);

  const addAddress = () =>
    setAddresses([
      ...addresses,
      addresses.length > 0 ? addresses[addresses.length - 1] + 1 : 0,
    ]);

  const deleteContact = (id) =>
    setContacts(contacts.filter((item) => item !== id));

  const deleteAddresses = (id) =>
    setAddresses(addresses.filter((item) => item !== id));

  const translateContactType = (contactType) => {
    switch (contactType) {
      case "Telefoon nr.":
        return { value: contactType, label: "Phone Number" };
      case "Mobiel nr.":
        return { value: contactType, label: "Mobile Number" };
      case "Overig":
        return { value: contactType, label: "Other" };
      default:
        return { value: contactType, label: contactType };
    }
  };

  const options = [
    { value: "shop", label: "shop" },
    { value: "delivery", label: "delivery" },
    { value: "postal", label: "postal" },
    { value: "invoice", label: "invoice" },
  ];

  const newContactTypes = contactTypes?.map(translateContactType);

  const onSubmit = (data) => {
    fetcher({
      url: `${dataUrl}`,
      method: method,
      payload: {
        customerId: customerId,
        name: data.name,
        description: data.description,
        supplierType: data.supplierType,
        contactDetails: contacts.map((id) => ({
          type: data["contactType" + id],
          value: data["contactValue" + id],
          notes: null,
        })),
        addresses: addresses.map((id) => ({
          type: data["addressType" + id],
          name: data["addressName" + id],
          street: data["street" + id],
          houseNumber: data["houseNumber" + id],
          suffix: data["suffix" + id],
          postalCode: data["postalCode" + id],
          city: data["city" + id],
          province: data["province" + id],
          country: data["country" + id],
        })),
      },
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        setShowModal(false);
        onSucces();
      })
      .catch((err) => {
        setError(err);
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to add or edit supplier",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <FormComponent
      dataUrl={dataUrl}
      method={method}
      content={formData}
      customOnSubmit={onSubmit}
    >
      {!!formData ? (
        <FormComponent.EditModal
          setShowModal={setShowModal}
          variant="link"
          size="sm"
        >
          <BsPencil className="btn-icon me-1" /> Edit
        </FormComponent.EditModal>
      ) : (
        <FormComponent.EditModal setShowModal={setShowModal} variant="primary">
          Add supplier
        </FormComponent.EditModal>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            {!!formData ? "Edit supplier" : "Add supplier"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="name"
              fieldLabel="Name"
              fieldRequired={true}
            />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="description"
              fieldLabel="Description"
              type="textarea"
            />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="supplierType"
              fieldLabel="Type"
              type="select"
              fieldArrayOptions={suppliersTypes}
            />
          </Row>
          {!!contacts && (
            <React.Fragment>
              <Row
                className="g-3 mb-3 d-flex justify-content-center"
                style={{ marginTop: "1.0em" }}
              >
                Contact Details:{" "}
              </Row>
              {contacts.map((id, index) => (
                <Row key={id} className="d-flex align-items-center g-3 mb-3">
                  <Col md={5}>
                    <FormComponent.Field
                      fieldName={"contactType" + id}
                      fieldLabel="Type"
                      fieldDefaultValue={formData?.contactDetails[index]?.type}
                      type="select"
                      fieldOptions={newContactTypes}
                    />
                  </Col>
                  <Col md={6}>
                    <FormComponent.Field
                      fieldName={"contactValue" + id}
                      fieldDefaultValue={formData?.contactDetails[index]?.value}
                      fieldLabel="contact"
                    />
                  </Col>
                  <Col>
                    <Button
                      style={{ padding: "0px" }}
                      size="sm"
                      variant="flush"
                      onClick={() => deleteContact(id)}
                    >
                      {<BsTrash color="red" />}
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                variant="link"
                onClick={() => addContact()}
                style={{ marginBottom: "1.5em" }}
              >
                + Add contact
              </Button>
            </React.Fragment>
          )}
          {!!addresses && (
            <React.Fragment>
              <Row
                className="g-3 mb-3 d-flex justify-content-center"
                style={{ marginTop: "1.0em" }}
              >
                Address Details:{" "}
              </Row>
              {addresses.map((id, index) => (
                <Card
                  className="g-3 mb-3 bg-dark bg-opacity-10"
                  body
                  key={index}
                >
                  <Card.Header className="d-flex justify-content-between">
                    {" "}
                    <Card.Title>
                      {formData?.addresses[id]?.name
                        ? `Address: ${formData?.addresses[id]?.name}`
                        : "New address"}
                    </Card.Title>
                    <Button
                      style={{ padding: "0px" }}
                      size="sm"
                      variant="flush"
                      onClick={() => deleteAddresses(id)}
                    >
                      {<BsTrash color="red" />}
                    </Button>
                  </Card.Header>

                  <Card.Body>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"addressType" + id}
                        fieldDefaultValue={formData?.addresses[id]?.type}
                        fieldLabel="Type address*"
                        fieldRequired={true}
                        type="select"
                        fieldOptions={options}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"addressName" + id}
                        fieldLabel="Name*"
                        fieldDefaultValue={formData?.addresses[id]?.name}
                        fieldRequired={true}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"street" + id}
                        fieldDefaultValue={formData?.addresses[id]?.street}
                        fieldLabel="Street*"
                        fieldRequired={true}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <Col>
                        <FormComponent.Field
                          fieldName={"houseNumber" + id}
                          fieldDefaultValue={
                            formData?.addresses[id]?.houseNumber
                          }
                          fieldLabel="House number*"
                          type="number"
                          fieldRequired={true}
                        />
                      </Col>
                      <Col>
                        <FormComponent.Field
                          fieldName={"suffix" + id}
                          fieldLabel="Suffix"
                          fieldDefaultValue={formData?.addresses[id]?.suffix}
                        />
                      </Col>
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"postalCode" + id}
                        fieldLabel="Postal code*"
                        fieldDefaultValue={formData?.addresses[id]?.postalCode}
                        fieldRequired={true}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"city" + id}
                        fieldLabel="City*"
                        fieldDefaultValue={formData?.addresses[id]?.city}
                        fieldRequired={true}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"province" + id}
                        fieldLabel="Province"
                        fieldDefaultValue={formData?.addresses[id]?.province}
                        fieldRequired={true}
                      />
                    </Row>
                    <Row className="g-2 mb-3">
                      <FormComponent.Field
                        fieldName={"country" + id}
                        fieldLabel="Country*"
                        fieldDefaultValue={formData?.addresses[id]?.country}
                        fieldRequired={true}
                      />
                    </Row>
                  </Card.Body>
                </Card>
              ))}
              <Button
                variant="link"
                onClick={() => addAddress()}
                style={{ marginBottom: "1.5em" }}
              >
                + Add address
              </Button>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FormComponent.CancelModal
            variant="primary"
            setShowModal={setShowModal}
            formData={formData}
          />
          <FormComponent.SaveModal variant="success" />
        </Modal.Footer>
      </Modal>
    </FormComponent>
  );
};

export default CustomerSuppliersForm;
