import React from "react";
// import { config } from "../../../../config";
// import useFetch from "../../../../utils/useFetch";
import CustomerMarketingProfileLogos from "./CustomerMarketingProfileLogos";
import CustomerMarketingProfileBrandlogos from "./CustomerMarketingProfileBrandlogos";
import { Col, Row } from "react-bootstrap";
import CustomerMarketingProfileSignatures from "./CustomerMarketingProfileSignatures";
import CustomerMPProperties from "./CustomerMPProperties";
import CustomerMarketingProfileSocialMedia from "./CustomerMarketingProfileSocialMedia";

const CustomerDetailsMarketingProfile = ({ mp, refreshMp }) => {
  /*
  const dataUrl1 = `${config.api.phoenix}/marketingprofiles/${customer?.id}/properties`;

  const {
    data: properities,
    error: propertiesError,
    mutate: refreshProperties,
  } = useFetch({
    url: dataUrl1,
    customerId: customer?.id,
  });

  */

  return (
    <Col>
      <CustomerMPProperties
        mpId={mp?.id}
        mpProperties={mp?.properties}
        refreshMp={refreshMp}
      />
      <Row className="g-3">
        <Col>
          <CustomerMarketingProfileSignatures
            signatures={mp?.signatures}
            mpId={mp?.id}
            refreshMp={refreshMp}
          />
        </Col>
      </Row>

      <Row className="g-3">
        <Col>
          <CustomerMarketingProfileLogos
            mp={mp}
            mpId={mp?.id}
            refreshMp={refreshMp}
          />
        </Col>
        <Col>
          <CustomerMarketingProfileBrandlogos
            mp={mp}
            mpId={mp?.id}
            refreshMp={refreshMp}
          />
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <CustomerMarketingProfileSocialMedia
            socialmedia={mp?.socialMedia}
            mpId={mp?.id}
            refreshMp={refreshMp}
          />
        </Col>
        <Col></Col>
      </Row>
    </Col>
  );
};

export default CustomerDetailsMarketingProfile;
