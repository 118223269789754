import React, { useState } from "react";
import { config } from "../../../config";
import ImgBox from "../../ui/ImgBox";
import ListPage from "../../ui/ListPage";
import { Link } from "react-router-dom";
import ActiveFilterSelect from "./ActiveFilterSelect";
import FuncActiveFilterSelect from "./FuncActiveFilterSelect";
import ApprovalFilter from "./ApprovalFilter";
import { Card } from "react-bootstrap";

const Variants = () => {
  const [active, setActive] = useState(true);
  const [functionallyActive, setFunctionallyActive] = useState(true);
  const [approvalFilter, setApprovalFilter] = useState("");

  const urlParamsObj = {
    active: active,
    functionallyActive: functionallyActive,
    approvalFilter: approvalFilter,
  };

  const urlParams = Object.entries(urlParamsObj)
    .map(([key, value]) => `&${key}=${value}`)
    .join("");

  const GridItem = ({ item }) => (
    <Link
      className="text-decoration-none hover-parent"
      to={`/variants/${item.id}`}
    >
      <Card className="border h-100 justify-content-between hover-border-primary thumbnail-card fade-in-grow-up delay">
        <Card.Body className="p-0">
          <div className="position-relative">
            <ImgBox
              imgId={item.recentProofFileId}
              api={config.api.proofs}
              className="w-100 hover-zoom"
              width={300}
              format="jpg"
            />
          </div>
        </Card.Body>
        <Card.Footer
          className="p-2 border-top-0 bg-px-dark-tint-85"
          style={{ flexBasis: "100%" }}
        >
          <Card.Title as="h6" className="mb-n1 pb-1 text-truncate fs-90">
            {item.name}
          </Card.Title>
          <div className="font-monospace text-secondary-tint-25 text-truncate">
            {item.id}
          </div>
        </Card.Footer>
      </Card>
    </Link>
  );

  const GridItemXL = ({ item }) => (
    <Link
      className="text-decoration-none hover-parent"
      to={`/variants/${item.id}`}
    >
      <Card className="border h-100 justify-content-between hover-border-primary thumbnail-card fade-in-grow-up delay">
        <Card.Body className="p-0">
          <div className="position-relative">
            <ImgBox
              imgId={item.recentProofFileId}
              api={config.api.proofs}
              className="w-100 hover-zoom"
              width={300}
              format="jpg"
            />
          </div>
        </Card.Body>
        <Card.Footer
          className="p-2 border-top-0 bg-px-dark-tint-85"
          style={{ flexBasis: "100%" }}
        >
          <Card.Title as="h6" className="mb-n1 pb-1 text-truncate fs-90">
            {item.name}
          </Card.Title>
          <div className="font-monospace text-secondary-tint-25 text-truncate">
            {item.id}
          </div>
        </Card.Footer>
      </Card>
    </Link>
  );

  return (
    <ListPage
      baseUrl={`${config.api.phoenix}/variants`}
      urlParams={urlParams}
      itemLabel="variant"
      itemPath="variants"
      orderOptions={[
        "name",
        "treatmentName",
        "treatmentCode",
        "programName",
        "mailMomentName",
        "mailMomentCode",
        "segmentName",
        "orderSourceAndSourceId",
      ]}
      searchParam="searchPhrase"
      gridItemXL={GridItemXL}
      gridItem={GridItem}
      thumbnailCol="recentProofFileId"
      tableItems={{
        recentProofFileId: {
          cellType: "thumbnail",
          cellWidth: 36,
          cellPadding: "0px",
          component: ({ value }) => (
            <ImgBox
              imgId={value}
              width={36}
              api={`${config.api.images}/proofs`}
              className="fade-in-grow delay"
              format="jpg"
            />
          ),
        },
        id: {},
        name: {},
        "customer.name": {
          displayHeading: "Customer",
        },
        "program.name": {
          displayHeading: "Program",
        },
        "mailMoment.name": {
          displayHeading: "MailMoment",
        },
        "treatment.name": {
          displayHeading: "Treatment",
        },
        "segment.name": {
          displayHeading: "Segment",
        },
      }}
      headerItems={
        <>
          <FuncActiveFilterSelect
            functionallyActive={functionallyActive}
            setFunctionallyActive={setFunctionallyActive}
          />
          <ActiveFilterSelect active={active} setActive={setActive} />
          <ApprovalFilter
            approvalFilter={approvalFilter}
            setApprovalFilter={setApprovalFilter}
          />
        </>
      }
      menuType="variants"
      colorClass="variant"
    />
  );
};

export default Variants;
