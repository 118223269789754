import React from "react";
import DetailPage from "../ui/DetailPage";
import { config } from "../../config";
import { Col, Row } from "react-bootstrap";
import FloatingInput from "../ui/FloatingInput";
import { PROGRAM_STATUS, PROGRAM_TYPE, RULES } from "../../constants";
import { useParams } from "react-router-dom";
import CheckboxInput from "../ui/CheckboxInput";
import ImgFileIdInput from "../ui/ImgFileIdInput";

const ProgramDetail = () => {
  const { programType: programTypeUrl } = useParams();
  const beforeSumbit = ({ id, ...data }) => {
    return data;
  };

  const FormLayout = ({
    register,
    errors,
    editable,
    isSubmitted,
    enableForm,
    watch,
  }) => {
    const formProps = {
      register: register,
      errors: errors,
      editable: editable,
      isSubmitted: isSubmitted,
      enableForm: enableForm,
      watch: watch,
    };

    return (
      <Row className="g-2">
        <Col xs={12}>
          <Row className="g-2">
            <Col>
              <ImgFileIdInput formProps={formProps} id="thumbnailFileId" />
            </Col>
            <Col>
              <ImgFileIdInput formProps={formProps} id="demoFileId" />
            </Col>
            <Col>
              <ImgFileIdInput formProps={formProps} id="bigSliderFileId" />
            </Col>
            <Col>
              <ImgFileIdInput formProps={formProps} id="smallSliderFileId" />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="g-2">
            <Col>
              <FloatingInput
                formProps={formProps}
                id="name"
                isRequired
                className="min-width-select"
              />
            </Col>

            <div className="mt-n2"></div>

            <Col>
              <FloatingInput
                formProps={formProps}
                id="marketingName"
                className="min-width-select"
              />
            </Col>
            <Col>
              <FloatingInput
                formProps={formProps}
                id="title"
                className="min-width-select"
              />
            </Col>
            <Col>
              <FloatingInput
                formProps={formProps}
                id="tooltip"
                className="min-width-select"
              />
            </Col>
            <Col>
              <FloatingInput
                formProps={formProps}
                id="theme"
                className="min-width-select"
              />
            </Col>

            <div className="mt-n2"></div>

            <Col>
              <FloatingInput
                formProps={formProps}
                id="programType"
                options={Object.values(PROGRAM_TYPE)}
              />
            </Col>
            <Col>
              <FloatingInput
                formProps={formProps}
                id="status"
                options={Object.values(PROGRAM_STATUS)}
              />
            </Col>

            <Col>
              <FloatingInput
                formProps={formProps}
                id="sequence"
                rules={RULES.integer}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                formProps={formProps}
                id="sliderSequence"
                rules={RULES.integer}
                className="min-width-input"
              />
            </Col>
            <div className="mt-n2"></div>

            <Col className="d-flex align-items-center">
              <CheckboxInput
                formProps={formProps}
                id="active"
                className="min-width-input"
              />
            </Col>
            <Col className="d-flex align-items-center">
              <CheckboxInput
                formProps={formProps}
                id="alwaysOrderable"
                className="min-width-select"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <FloatingInput
            formProps={formProps}
            id="description"
            as="textarea"
            className="stretch-height"
          />
        </Col>
      </Row>
    );
  };

  return (
    <DetailPage
      baseUrl={`${config.api.phoenix}/programs`}
      itemLabel={programTypeUrl}
      itemImages={["demoFileId", "bigSliderFileId", "smallSliderFileId"]}
      formLayout={FormLayout}
      beforeSumbit={beforeSumbit}
      menuType={programTypeUrl}
      colorClass={programTypeUrl}
    />
  );
};

export default ProgramDetail;

// const testProgram = {
//   id: 2159,

//   thumbnailFileId: null,

//   demoFileId: null,
//   bigSliderFileId: null,
//   smallSliderFileId: null,

//   name: "Test Cycle 2",
//   marketingName: null,
//   title: null,
//   tooltip: null,
//   theme: null,

//   type: "cycle",
//   status: "open",
//   sequence: null,
//   sliderSequence: null,

//   active: true,
//   alwaysOrderable: false,

//   description: null,

//   dropDateSchedules: [],
//   mailMoments: [
//     {
//       id: 8249,
//       name: "Default mailmoment",
//       shortName: "M0",
//       code: "00",
//       description: null,
//       sequence: 0,
//       circlePhase: null,
//       deltaDaysFrom: null,
//       deltaDaysTo: null,
//       dateType: null,
//       thumbnailFileId: null,
//       active: true,
//       responseTime: 0,
//     },
//   ],
// };
