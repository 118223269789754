import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../utils/useFetch";
import { config } from "../../../../config";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import { Card, Col, Row } from "react-bootstrap";
import { BsBriefcase, BsCake, BsPerson } from "react-icons/bs";
import CustomerContactDetails from "./CustomerContactDetails";
import CustomerContactDetailsForm from "./CustomerContactDetailsForm";
import fetcher from "../../../../utils/fetcher";
import { useAuthContext } from "../../../../context/AuthContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import BtnWithDialog from "../../../ui/BtnWithDialog";

const CustomerDetailsContactPersons = () => {
  const { customerId } = useParams();
  const { setToasts, client } = useAuthContext();
  const [error, setError] = useState(null);

  const {
    data: persons,
    error: personsError,
    mutate: refreshContactDetails,
  } = useFetch({
    url: `${config.api.phoenix}/persons?customerId=${customerId}&pageSize=30`,
    customerId: customerId,
  });

  const { data: contactTypes, error: contactTypesError } = useFetch({
    url: `${config.api.phoenix}/persons/ContactDetailTypes`,
  });

  const formatDoB = (date) => {
    if (date !== null) {
      const [datePart] = date.split("T");
      const [year, month, day] = datePart.split("-");
      return {
        day: day,
        month: month,
        year: year,
      };
    }
  };

  const handleDelete = (id) => {
    fetcher({
      url: `${config.api.phoenix}/persons/${id}`,
      method: "DELETE",
      payload: {},
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refreshContactDetails();
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to delete contact person",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <WithLoaderAndError
      isLoading={!persons || !contactTypes}
      error={personsError || contactTypesError}
    >
      <Row className="g-3">
        {!!persons
          ? persons.map((item) => (
              <Col key={item.id} md={6} lg={6} xl={4} xxl={4} xxxl={3}>
                <Card className="bg-dark bg-opacity-10">
                  <Card.Header>
                    <Card.Title>
                      {item.prefix} {item.firstName} {item.lastName}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body
                    className="d-flex flex-column"
                    style={{ minHeight: "12em" }}
                  >
                    {!!item.id ? (
                      <p>
                        <BsPerson /> {"Person ID: "} {item.id}
                      </p>
                    ) : null}
                    {!!item.roles.length ? (
                      <p>
                        <BsBriefcase /> {"Role: "}{" "}
                        {item.roles.map((role, index) => (
                          <span key={role}>
                            <span>{role}</span>
                            {index < item.roles.length - 1 && ", "}{" "}
                          </span>
                        ))}
                      </p>
                    ) : null}
                    {!!item.dateOfBirth ? (
                      <p>
                        <BsCake /> {"Birthday: "}{" "}
                        {formatDoB(item.dateOfBirth).day}
                        {"-"}
                        {formatDoB(item.dateOfBirth).month}
                        {"-"}
                        {formatDoB(item.dateOfBirth).year}
                      </p>
                    ) : null}

                    {!!item.contactDetails.length ? (
                      <div className="mt-auto text-right">
                        <CustomerContactDetails item={item} />
                      </div>
                    ) : null}
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-end align-items-center">
                    <CustomerContactDetailsForm
                      formData={item}
                      contactTypes={contactTypes}
                      onSucces={() => refreshContactDetails()}
                    />
                    <BtnWithDialog
                      title={"Delete this contact person"}
                      children={
                        "Are you sure you want to delete this contact person?"
                      }
                      variant="link"
                      onConfirm={() => handleDelete(item.id)}
                    />
                  </Card.Footer>
                </Card>
              </Col>
            ))
          : null}
      </Row>
      <div className="text-right" style={{ marginTop: "0.5rem" }}>
        <CustomerContactDetailsForm
          onSucces={() => refreshContactDetails()}
          contactTypes={contactTypes}
        />
      </div>
    </WithLoaderAndError>
  );
};

export default CustomerDetailsContactPersons;
