import { useLocation } from "react-router-dom";
import { menuItems } from "../content/itemTypes";

const useBreadcrumbs = ({ path = [], pageTitle = "" }) => {
  const findMenuItem = (pathName) =>
    menuItems.find((item) => item?.path === pathName);

  const { pathname } = useLocation();
  const pathArr = pathname.split("/");

  const listPageItems = pathArr
    .filter((item, i) => item?.length) // filter out second empty segment
    .map((item) => {
      const menuItem = findMenuItem(item);
      return menuItem ? { url: "/" + menuItem?.path, ...menuItem } : {};
    })
    .filter((item) => !!item?.title);

  const dashboard = menuItems.find((item) => item?.title === "Dashboard");

  const breadcrumbLinks = [{ url: "/", ...dashboard }, ...listPageItems];

  const defaultCurrentPage = pageTitle
    ? { title: pageTitle, url: pathname }
    : breadcrumbLinks.pop();

  const currentPagePath = pathArr?.[pathArr?.length - 1];
  const currentPageConfig = findMenuItem(currentPagePath) || {};
  const currentPage = { ...currentPageConfig, ...defaultCurrentPage };

  const lastListPage = listPageItems[listPageItems.length - 1];

  const activeModule = listPageItems?.length
    ? {
        ...lastListPage,
        isActiveItem: currentPage?.title === lastListPage?.title,
      }
    : { ...dashboard, isActiveItem: currentPage?.title === dashboard?.title };

  const breadcrumbs = [
    ...breadcrumbLinks,
    ...path,
    { icon: activeModule?.icon, ...currentPage, url: "" },
  ];
  return { breadcrumbs, activeModule, currentPage };
};

export default useBreadcrumbs;
