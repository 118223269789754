export const config = {
  name: {
    title: "Phoenix",
    subTitle: "WEB",
  },
  auth: {
    appUrl: process.env.REACT_APP_HOST,
    bffUrl: process.env.REACT_APP_BFF_HOST,
    testClient: 10000,
    testCustomer: 119442,
  },
  api: {
    phoenix: `${process.env.REACT_APP_BFF_HOST}/phoenix`,
    order: `${process.env.REACT_APP_BFF_HOST}/order`,
    reporting: `${process.env.REACT_APP_BFF_HOST}/reporting`,
    invoicing: `${process.env.REACT_APP_BFF_HOST}/invoicing`,
    files: `${process.env.REACT_APP_BFF_HOST}/files`,
    images: "https://files.llcrm.net",
    fileUpload: `https://files.api.llcrm.net`,
    proofs: "https://files.llcrm.net/proofs",
  },
  ui: {
    showCssHelpers: process.env.REACT_APP_SHOW_CSS_HELPERS,
  },
};
