import React from "react";
import {
  FloatingLabel,
  FormControl,
  FormSelect,
  FormText,
  Form,
} from "react-bootstrap";

const FloatingInput = ({
  id,
  isRequired,
  label,
  placeholder,
  formProps,
  rules = {},
  options,
  editable,
  helpText,
  ...props
}) => {
  const { register, errors, isSubmitted, watch } = formProps;
  const value = watch(id) || ""; // Ensure value is always a string

  return !!id && !!label ? (
    <FloatingLabel controlId={id} label={label}>
      {options && options?.[0] ? (
        <FormSelect
          placeholder={placeholder || label || null}
          {...register(id, {
            required: !!isRequired,
            ...rules,
          })}
          value={value} // Ensure value is always a string
          isInvalid={!!errors?.[id]}
          isValid={isSubmitted && !errors?.[id]}
          readOnly={!editable}
          disabled={!editable}
          {...props}
        >
          {options.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </FormSelect>
      ) : (
        <FormControl
          placeholder={placeholder || label || null}
          {...register(id, {
            required: !!isRequired,
            ...rules,
          })}
          value={value} // Ensure value is always a string
          isInvalid={!!errors?.[id]}
          isValid={isSubmitted && !errors?.[id]}
          readOnly={!editable}
          disabled={!editable}
          {...props}
        />
      )}
      {errors?.[id] && (
        <Form.Control.Feedback type="invalid">
          {errors?.[id]?.message}
        </Form.Control.Feedback>
      )}
      {helpText && <FormText>{helpText}</FormText>}
    </FloatingLabel>
  ) : null;
};

export default FloatingInput;
