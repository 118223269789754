import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { BsCheck2, BsFunnelFill } from "react-icons/bs";
import camelCaseToText from "../../../utils/camelCaseToText";

const ApprovalFilter = ({ approvalFilter, setApprovalFilter }) => {
  const OPTIONS = [
    "customerApproved",
    "approved",
    "allApproved",
    "pendingCustomerApproval",
    "pendingApproval",
    "pendingAllApproval",
    "pendingAnyApproval",
    "pendingOnlyCustomerApproval",
  ];
  return (
    <Dropdown as={ButtonGroup} className="filter-menu">
      <Dropdown.Toggle
        variant="outline-secondary"
        size="sm"
        active={!!approvalFilter}
        title="Approval filter"
      >
        <BsFunnelFill /> Approval filter
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {OPTIONS.map((option) => (
          <Dropdown.Item
            as="span"
            className="cursor-pointer"
            onClick={() =>
              setApprovalFilter((curr) => (curr === option ? "" : option))
            }
            active={approvalFilter === option}
            key={option}
          >
            <BsCheck2
              className={`${
                approvalFilter !== option ? "opacity-0" : ""
              } text-success me-2`}
            />
            {camelCaseToText(option)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ApprovalFilter;
