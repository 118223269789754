import axios from "axios";
import React, { useState, useContext, useMemo, useEffect } from "react";
import { config } from "../config";
import useFetch from "../utils/useFetch";

export const AuthContext = React.createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [toasts, setToasts] = useState([]);

  // Favorite views
  const {
    data: userSettings,
    error: userSettingsError,
    mutate: mutateUserSettings,
  } = useFetch({
    url: `${config.api.reporting}/usersettings`,
    clientId: client?.id,
    shouldFetch: () => !!client?.id && !!user,
  });

  const authStore = useMemo(() => {
    const login = () => {
      window.location.href = `${config.auth.bffUrl}/auth/login?returnUrl=${config.auth.appUrl}/`;
    };

    const logout = () => {
      window.location.href = `${config.auth.bffUrl}/auth/logout?returnUrl=${config.auth.appUrl}/login`;
    };

    const isFavorite = ({ viewId }) => {
      return (
        userSettings &&
        !!userSettings?.favoriteViews.filter((v) => v.id === +viewId)?.length
      );
    };

    return {
      isLoading,
      user,
      login,
      logout,
      client,
      setClient,
      userSettings,
      userSettingsError,
      mutateUserSettings,
      isFavorite,
      toasts,
      setToasts,
    };
  }, [
    user,
    client,
    isLoading,
    userSettings,
    userSettingsError,
    mutateUserSettings,
    toasts,
  ]);

  useEffect(() => {
    // get user
    const getUser = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${config.auth.bffUrl}/auth/userinfo`,
          {
            withCredentials: true,
          }
        );
        setUser(data);
      } catch (error) {
        setUser(null);
        console.error("Failed to get user", error);
      } finally {
        setIsLoading(false);
      }
    };
    getUser();

    // get client from storage
    setClient(
      JSON.parse(localStorage.getItem("client")) ?? {
        id: config.auth.testClient,
        code: "TEST",
        customer: null,
      }
    );
    setClient((prevClient) => ({ ...prevClient, customer: null }));
  }, []);

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
