import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import { config } from "../../../config";
import fetcher from "../../../utils/fetcher";

const CampaignDetailsForm = ({ campaign, refetch }) => {
  const [editable, setEditable] = useState(false);

  const defaultValues = {
    name: campaign.name,
    title: campaign.title,
    marketingName: campaign.marketingName,
    theme: campaign.theme,
    description: campaign.description,
    tooltip: campaign.tooltip,
    sequence: campaign.sequence,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty },
    reset,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (data) =>
    fetcher({
      url: `${config.api.phoenix}/program/${campaign.id}`,
      method: "put",
      customerId: config.auth.testCustomer,
      payload: { ...campaign, ...data },
    })
      .then((res) => {
        refetch();
        setEditable(false);
      })
      .catch((err) => {
        setError(err);
      });

  const [error, setError] = useState(null);

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset({ ...defaultValues })}
    >
      <Card body className="mb-3 bg-dark bg-opacity-10">
        <Card.Title>Campaign details</Card.Title>
        <Row className="g-3">
          <Col>
            <FloatingLabel controlId="name" label="Name*" className="mb-3">
              <Form.Control
                placeholder="Name*"
                {...register("name", {
                  required: true,
                })}
                isInvalid={errors?.name}
                isValid={isSubmitted && !errors?.name}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
            <FloatingLabel controlId="title" label="Title" className="mb-3">
              <Form.Control
                placeholder="Title"
                {...register("title")}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
            <FloatingLabel controlId="tooltip" label="Tooltip" className="mb-3">
              <Form.Control
                placeholder="Tooltip"
                {...register("tooltip")}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="marketingName"
              label="Marketing name"
              className="mb-3"
            >
              <Form.Control
                placeholder="Marketing name"
                {...register("marketingName")}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
            <FloatingLabel controlId="theme" label="Theme">
              <Form.Control
                placeholder="Theme"
                {...register("theme")}
                readOnly={!editable}
                disabled={!editable}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="Description" controlId="description">
              <Form.Control
                placeholder="Description"
                as="textarea"
                style={{ height: "354px" }}
                {...register("description")}
                readOnly={!editable}
                disabled={!editable}
              />
              <Form.Text muted>
                Plain-text or HTML (HTML editor to be added)
              </Form.Text>
            </FloatingLabel>
          </Col>
        </Row>
        <hr />
        {editable ? (
          <div className="text-end">
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                reset({ ...defaultValues });
                setEditable(false);
              }}
            >
              <BsX className="btn-icon me-1" /> Cancel
            </Button>{" "}
            <Button variant="success" type="submit" disabled={!isDirty}>
              <BsCheck2 className="btn-icon me-1" /> Save changes
            </Button>
          </div>
        ) : (
          <div className="text-end">
            <Button
              type="button"
              variant="success"
              onClick={() => setEditable(true)}
            >
              <BsPencil className="btn-icon me-1" /> Edit
            </Button>
          </div>
        )}
      </Card>
      <ToastContainer
        className="p-3"
        containerPosition="fixed"
        position="bottom-end"
      >
        <Toast
          onClose={() => setError(null)}
          show={!!error}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Failed to update program</strong>
            <small className="text-muted">
              {error?.code || "Something went wrong"}
            </small>
          </Toast.Header>
          <Toast.Body>
            <div>{error?.message || "An unexpected error occurred"}</div>
            {!!error?.response?.data?.length ? (
              <div className="px-3 py-1 mt-2 bg-danger bg-opacity-25">
                {error.response.data.map((errorLine, i) => (
                  <code key={i} className="d-block my-2">
                    {errorLine}
                  </code>
                ))}
              </div>
            ) : null}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Form>
  );
};

export default CampaignDetailsForm;
