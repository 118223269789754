import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import fetcher from "../../../../utils/fetcher";
import { config } from "../../../../config";
import { useAuthContext } from "../../../../context/AuthContext";
import camelCaseToText from "../../../../utils/camelCaseToText";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import happy from "../../../../assets/il-happy.svg";
import surprise from "../../../../assets/il-surprise.svg";
import { RULES } from "../../../../constants";
import Loader from "../../../ui/Loader";

const MPPropField = ({
  register,
  name = "",
  rows = 1,
  helpText = "",
  label = "",
  rules = {},
  dirtyFields,
  isSubmitted,
  errors,
  editable,
}) => {
  const isDirty = !!dirtyFields?.[name];
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Col>
      <div className="position-relative">
        {isFocused ? (
          <Badge
            className="font-monospace position-absolute top-0 end-0 translate-middle-y z-index-1 bg-primary-tint-50 me-2 fs-70"
            bg={null}
          >
            {name}
          </Badge>
        ) : null}
        <FloatingLabel
          label={
            <span className={`${isDirty ? "text-success-shade-25" : ""}`}>
              {isDirty ? (
                <BsPencil className="me-2 text-success opacity-50" />
              ) : null}
              {label || camelCaseToText(name)}
            </span>
          }
          controlId={name}
        >
          <Form.Control
            placeholder={camelCaseToText(name)}
            as="textarea"
            {...register(name, rules)}
            readOnly={!editable}
            isInvalid={!!errors?.[name]}
            isValid={isSubmitted && !errors?.[name]}
            rows={rows}
            className={`${rows > 1 ? "h-auto" : ""}`}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </FloatingLabel>
        {errors?.[name]?.message ? (
          <Form.Text className="d-block text-danger-shade-10 text-right form-text mb-n2 mt-0">
            {errors?.[name]?.message}
          </Form.Text>
        ) : null}
        {helpText ? <Form.Text>{helpText}</Form.Text> : null}
      </div>
    </Col>
  );
};

const CustomerMPProperties = ({ mpProperties = {}, mpId, refreshMp }) => {
  const { client } = useAuthContext();
  const [editable, setEditable] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [toasts, setToasts] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
    reset,
  } = useForm({
    defaultValues: mpProperties,
  });

  const dataUrl = `${config.api.phoenix}/marketingprofiles/${mpId}/properties`;

  const onSubmit = (data) => {
    setEditable(false);
    setIsBusy(true);
    fetcher({
      url: dataUrl,
      method: "put",
      customerId: mpId,
      clientId: client?.id,
      payload: data,
    })
      .then((res) => {
        refreshMp();
        reset(data);
        setToasts([
          ...toasts,
          {
            title: "Success!",
            message: "Customer updated",
            variant: "success",
            img: happy,
          },
        ]);
      })
      .catch((err) => {
        setToasts([
          ...toasts,
          {
            title: "Something went wrong",
            message: "Could not update customer",
            variant: "danger",
            img: surprise,
            ...err,
          },
        ]);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const onCancel = () => {
    setEditable(false);
    reset(mpProperties);
  };

  const groupedProps = {
    general: [
      { name: "highlights", rows: 7, label: "Customer Highlights" },
      { name: "psLine", rows: 7, label: "ps_line" },
    ],
    addressBlock: [
      { name: "addressBlock", rows: 7 },
      { name: "addressBlockHtml", rows: 7, label: "Address block HTML" },
    ],
    addressBlockValues: [
      { name: "telephone" },
      { name: "fax" },
      { name: "city" },
    ],
    email: [
      { name: "senderName" },
      { name: "senderEmail", rules: RULES.email },
      { name: "aliasEmail", rules: RULES.email },
      { name: "customerEmail", rules: RULES.email },
    ],
    website: [
      { name: "qrCode", label: "QR code" },
      { name: "customerUrl", rules: RULES.url, label: "Customer URL" },
      { name: "clientSurveyUrl", rules: RULES.url, label: "Client survey URL" },
    ],
  };

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset(mpProperties)}
      onKeyDown={(e) => {
        e.code === "Escape" && onCancel();
        if (e.code === "Enter" && !!e.ctrlKey) {
          handleSubmit(onSubmit)();
        }
      }}
    >
      <Card
        className="mb-3 bg-gray-400 border-0 position-relative"
        onClick={() => !editable && setEditable(true)}
      >
        <Card.Body className="pb-0">
          <Row className="g-3">
            <Col>
              <Card body className="border-0 h-100">
                <h3>General</h3>
                <Row className="g-3" sm={1}>
                  {groupedProps.general.map((item) => (
                    <MPPropField
                      key={item.name}
                      {...item}
                      register={register}
                      editable={editable}
                      dirtyFields={dirtyFields}
                      isSubmitted={isSubmitted}
                      errors={errors}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
            <Col>
              <Card body className="border-0 h-100">
                <h3>Email</h3>
                <Row className="g-3" sm={1}>
                  {groupedProps.email.map((item) => (
                    <MPPropField
                      key={item.name}
                      {...item}
                      register={register}
                      editable={editable}
                      dirtyFields={dirtyFields}
                      isSubmitted={isSubmitted}
                      errors={errors}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
            <Col>
              <Card body className="border-0 h-100">
                <h3>Website</h3>
                <Row className="g-2" sm={1}>
                  {groupedProps.website.map((item) => (
                    <MPPropField
                      key={item.name}
                      {...item}
                      register={register}
                      editable={editable}
                      dirtyFields={dirtyFields}
                      errors={errors}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
            <Col xxl={5}>
              <Card body className="border-0 h-100">
                <h3>Address block</h3>
                <Row className="g-3">
                  <Col>
                    <Row className="g-3" sm={1}>
                      {groupedProps.addressBlock.map((item) => (
                        <MPPropField
                          key={item.name}
                          {...item}
                          register={register}
                          editable={editable}
                          dirtyFields={dirtyFields}
                          isSubmitted={isSubmitted}
                          errors={errors}
                        />
                      ))}
                    </Row>
                  </Col>
                  <Col>
                    <Row className="g-3" sm={1}>
                      {groupedProps.addressBlockValues.map((item) => (
                        <MPPropField
                          key={item.name}
                          {...item}
                          register={register}
                          editable={editable}
                          dirtyFields={dirtyFields}
                          isSubmitted={isSubmitted}
                          errors={errors}
                        />
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0 pb-3 pt-3 bg-gray-400 d-flex justify-content-end position-sticky bottom-0 z-index-2">
          {editable ? (
            <>
              <Button
                variant="secondary"
                disabled={isBusy}
                onClick={onCancel}
                className="me-2 mw-8"
              >
                <BsX className="btn-icon me-1" />
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={isBusy || !isDirty}
                type="submit"
                className="mw-8"
              >
                <BsCheck2 className="btn-icon me-1" /> Save
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="success"
                disabled={isBusy}
                onClick={() => setEditable(true)}
                className="mw-8"
              >
                <BsPencil className="btn-icon me-1" /> Edit
              </Button>
            </>
          )}
        </Card.Footer>
        {isBusy ? <Loader overlay /> : null}
      </Card>
      <ToastContainer
        className="p-2"
        containerPosition="fixed"
        position="bottom-end"
      >
        {toasts.map((toast, i) => (
          <Toast
            onClose={() => setToasts(toasts.filter((t, ti) => ti !== i))}
            show={!!toast}
            className={`color-light bg-${toast?.variant || "danger"}-tint-10`}
            delay={2000}
            autohide
            key={`toast-${i}`}
          >
            <Toast.Header closeVariant="white">
              <strong className="me-auto">{toast.title || "Error"}</strong>
              <small className="font-monospace text-opacity-75">
                {toast?.code || ""}
              </small>
            </Toast.Header>
            <Toast.Body>
              <div>{toast?.message || "An unexpected error occurred"}</div>
              {toast.img ? (
                <img
                  src={toast.img}
                  alt={toast?.title}
                  className="fade-in-grow-up delay-500ms"
                  style={{
                    position: "absolute",
                    width: "80px",
                    top: "-75px",
                    left: "15px",
                  }}
                />
              ) : null}
              {!!toast?.response?.data && Array.isArray(toast.response.data) ? (
                <div className="px-3 py-1 mt-2 bg-danger bg-opacity-25">
                  {toast.response.data.map((errorLine, i) => (
                    <code key={i} className="d-block my-2">
                      {errorLine}
                    </code>
                  ))}
                </div>
              ) : null}
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </Form>
  );
};

export default CustomerMPProperties;
