import React, { useContext, useEffect, useState } from "react";
import { config } from "../../../../config";
import useFetch from "../../../../utils/useFetch";
import { useAuthContext } from "../../../../context/AuthContext";
import { Badge, Button, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { useFormContext } from "react-hook-form";
import { EditableContext } from "../../../ui/FormComponent";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { BsX } from "react-icons/bs";
import DropdownSearch from "../../../ui/DropdownSearch";

const CustomerDetailsLabels = ({ type }) => {
  const { client, setToasts } = useAuthContext();
  const { customerId } = useParams();
  const { setValue, watch } = useFormContext();
  const { editable } = useContext(EditableContext);
  const dataUrl = `${config.api.phoenix}/enumerations/${type}`;

  const fieldName =
    type === "internallabels"
      ? "internalLabels"
      : type === "externallabels"
      ? "externalLabels"
      : null;
  const [labels, setLabels] = useState(watch(fieldName) || []);

  const handleAddLabel = (newLabel) => {
    const isLabelExist = labels.some((label) => label.id === newLabel.id);
    if (!isLabelExist) {
      const updatedLabels = [...labels, newLabel];
      setLabels(updatedLabels);
    }
  };

  const handleRemoveLabel = (labelToRemove) => {
    const updatedLabels = labels.filter(
      (label) => label.id !== labelToRemove.id
    );
    setLabels(updatedLabels);
  };

  const { data: labelData, error: labelError } = useFetch({
    url: dataUrl,
    customerId: customerId,
    clientId: client?.id,
  });

  useEffect(() => {
    if (!editable) {
      setLabels(watch(fieldName) || []);
    }
  }, [editable, fieldName, watch]);

  useEffect(() => {
    if (labelError && labelError?.message !== "Network Error") {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Error message for Invoice Settings",
          delay: 50000,
          text: createDetailedErrorMessage(labelError),
        },
      ]);
    }
  }, [labelError, setToasts]);

  useEffect(() => {
    setValue(fieldName, labels, { shouldDirty: true });
  }, [labels, setValue, fieldName]);

  return (
    <Card body className="mb-3 bg-dark bg-opacity-10 my-3">
      <Card.Title>
        {type === "internallabels" ? "Internal labels" : "External labels"}
      </Card.Title>
      <Card.Body>
        <div>
          {labels.map((label, index) => (
            <Badge
              key={label.name}
              className="badge-custom font-monospace bg-opacity-50"
              bg="primary"
            >
              {label.name}
              {editable ? (
                <Button
                  className="btn-in-badge"
                  style={{ padding: "0px" }}
                  variant="link"
                  onClick={() => handleRemoveLabel(label)}
                  disabled={!editable}
                >
                  <BsX style={{ color: "darkblue", padding: "0px" }} />
                </Button>
              ) : null}
            </Badge>
          ))}
        </div>
        {editable ? (
          <DropdownSearch options={labelData} onSelect={handleAddLabel} />
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default CustomerDetailsLabels;
