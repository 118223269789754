import React, { useState, useContext, useMemo } from "react";

export const DrawerContext = React.createContext();
export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(window?.innerWidth >= 1200);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [query, setQuery] = useState("");

  const drawerStore = useMemo(() => {
    const handleShowOffCanvas = () => {
      setShowOffCanvas(true);
      handleCloseMenu();
    };
    const handleCloseOffCanvas = () => setShowOffCanvas(false);
    const handleLookup = (q) => {
      setQuery(q);
      handleShowOffCanvas();
    };

    const handleCloseMenu = () => setShowMenu(false);
    const handleShowMenu = () => setShowMenu(true);

    return {
      showOffCanvas,
      showMenu,
      setShowMenu,
      query,
      showSelectClient,
      setShowSelectClient,
      setQuery,
      handleShowOffCanvas,
      handleCloseOffCanvas,
      handleCloseMenu,
      handleShowMenu,
      handleLookup,
    };
  }, [showOffCanvas, showMenu, query, showSelectClient]);

  return (
    <DrawerContext.Provider value={drawerStore}>
      {children}
    </DrawerContext.Provider>
  );
};
