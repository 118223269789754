const camelCaseToText = (str) => {
  return (
    str.charAt(0).toUpperCase() +
    str
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .slice(1)
      .replace(" id", " ID")
  );
};

export default camelCaseToText;
