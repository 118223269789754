import React from "react";
import { Helmet } from "react-helmet-async";
import { config } from "../../config";

const PageHelmet = ({ pageTitle, moduleTitle = "" }) => {
  const titleWithModule = `${
    moduleTitle && moduleTitle !== pageTitle ? `${moduleTitle} | ` : ""
  }${pageTitle}`;
  return (
    <Helmet
      titleTemplate={`%s | ${config.name.title}${config.name.subTitle}`}
      title={titleWithModule}
    />
  );
};

export default PageHelmet;
