import React, { useEffect, useState } from "react";
import ListPage from "../ui/ListPage";
import { config } from "../../config";
import QSIcon from "../custom/QSIcon";
import ImgBox from "../ui/ImgBox";
import { CHART_TYPE } from "../../constants";
import { useLocation, useParams } from "react-router-dom";
import { BsFillQuestionCircleFill, BsTable } from "react-icons/bs";
import FavoriteButton from "../custom/FavoriteButton";
import ReportItemCard from "../ui/ReportItemCard";

const Reports = () => {
  const { chartType: chartTypeParam } = useParams();
  const { pathname } = useLocation();

  const { QUICKSIGHT, ACTIONLIST } = CHART_TYPE;

  const chartType =
    !!chartTypeParam &&
    QUICKSIGHT.toLowerCase() === chartTypeParam.toLowerCase()
      ? QUICKSIGHT
      : !!chartTypeParam &&
        ACTIONLIST.toLowerCase() === chartTypeParam.toLowerCase()
      ? ACTIONLIST
      : "";

  const source = pathname.indexOf("multiclient") > 0 ? "reports" : "views";

  const [chartTypeFilter, setChartTypeFilter] = useState(chartType || "");

  useEffect(() => {
    setChartTypeFilter((curr) => (!!chartType ? chartType : curr || ""));
  }, [chartType]);

  const GridItem = ({ item }) => (
    <ReportItemCard
      reports={true}
      {...item}
      itemLink={`/reports/${item?.chartType?.toLowerCase()}/${item?.reportId}/${
        item?.id
      }`} // Add box-shadow for depth, remove margin if any
    />
  );

  const [expandedItems, setExpandedItems] = useState([]);
  const toggleExpandedView = (itemId) => {
    setExpandedItems((prevItems) => {
      const isItemExpanded = prevItems.includes(itemId);
      if (isItemExpanded) {
        return prevItems.filter((existingItemId) => existingItemId !== itemId);
      } else {
        return [...prevItems, itemId];
      }
    });
  };

  return (
    <ListPage
      baseUrl={
        source === "views"
          ? `${config.api.reporting}/views`
          : `${config.api.reporting}/reports`
      }
      clientId={null}
      customerId={null}
      urlParams={`&chartType=${chartTypeFilter}`}
      itemLabel={
        chartType === QUICKSIGHT
          ? "QuickSight report"
          : chartType === ACTIONLIST
          ? "action list"
          : "report"
      }
      getItemLink={
        source === "reports"
          ? ({ id: reportId }) => `/report/${reportId}`
          : ({ reportId, id: viewId, chartType: viewChartType }) =>
              `/reports/${viewChartType.toLowerCase()}/${reportId}/${viewId}`
      }
      gridItem={GridItem}
      tableItems={
        source === "reports"
          ? {
              chartType: {
                displayHeading: " ",
                cellType: "thumbnail",
                component: ({ value }) => (
                  <ImgBox
                    width={46}
                    noStretch
                    className="fade-in-grow delay m-n2 bg-transparent text-primary-tint-25"
                    style={{ color: "#5dbbdb" }}
                    icon={
                      value === QUICKSIGHT
                        ? QSIcon
                        : value === ACTIONLIST
                        ? BsTable
                        : BsFillQuestionCircleFill
                    }
                    title={
                      value === QUICKSIGHT
                        ? "QuickSight"
                        : value === ACTIONLIST
                        ? "Action-list"
                        : null
                    }
                  />
                ),
              },
              id: {
                displayHeading: "Report ID",
              },
              name: {},
              category: {},
              description: {},
            }
          : {
              ...(chartType === ACTIONLIST
                ? {
                    button: {
                      cellType: "expandbutton",
                      bringToFront: true,
                      cellWidth: "10px",
                      displayHeading: " ",
                    },
                  }
                : {}),

              action: {
                mergeWith: "id",
                bringToFront: true,
                cellWidth: chartType === QUICKSIGHT ? "46px" : "10PX",
                cellClass: chartType === QUICKSIGHT ? "text-end" : "auto",
                displayHeading: " ",
                component: ({ extraValue }) => (
                  <FavoriteButton
                    viewId={extraValue}
                    className="position-relative"
                  />
                ),
              },

              chartType: {
                displayHeading: " ",
                cellType: "thumbnail",
                component: ({ value }) => (
                  <ImgBox
                    width={46}
                    noStretch
                    className={`fade-in-grow delay m-n2 bg-transparent ${
                      value === QUICKSIGHT
                        ? "text-info"
                        : value === ACTIONLIST
                        ? "text-teal"
                        : "text=secondary"
                    }`}
                    icon={
                      value === QUICKSIGHT
                        ? QSIcon
                        : value === ACTIONLIST
                        ? BsTable
                        : BsFillQuestionCircleFill
                    }
                    title={null}
                  />
                ),
              },
              id: {
                displayHeading: "View ID",
              },
              reportId: {
                cellType: "id",
                displayHeading: "Report ID",
              },
              name: {},
              path: {
                cellClass: "text-start",
              },
            }
      }
      layoutOptions={["table", "grid"]}
      searchParam={"search"}
      orderOptions={[]}
      thumbnailCol="chartType"
      showNewItemBtn={false}
      pageSizes={source === "reports" ? [20, 30] : []}
      expandedItems={expandedItems}
      toggleExpandedView={toggleExpandedView}
      reports={true}
      menuType={chartType}
      group="path"
      colorClass={chartType === QUICKSIGHT ? "quickSight" : "actionList"}
    />
  );
};

export default Reports;
