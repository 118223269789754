import axios from "axios";
import fetcher from "./fetcher";

const uploadFile = ({
  format,
  fileName,
  fileCategory = "asset",
  file,
  onError,
  url,
  customerId,
  clientId,
  onSuccess
  
}) => {
 

  fetcher({
    method: "POST",
    url: url,
    payload: {
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
    },
    withCredentials: true,
    clientId,
    customerId
  })
    .then((res) => {
      if (!!res.data.redirectResult) {
        axios({
          method: "PUT",
          url: res.data.redirectResult,
          data: file,
          headers: {
            "content-type": "application/octet-stream",
          },
        })
          .then((uploadRes) => {
            !!onSuccess && onSuccess(res?.data);
          })
          .catch((error) => {
            onError(error);
          });
      } else {
        onError(res?.statusText);
      }
    })
    .catch((error) => {
        onError(error);
    });
};

export default uploadFile;