import React from "react";
import DetailPage from "../ui/DetailPage";
import { config } from "../../config";
import { Col, Row } from "react-bootstrap";
import FloatingInput from "../ui/FloatingInput";
import { RULES } from "../../constants";

const ProductDetail = () => {
  const beforeSumbit = ({ id, baseAmount, stepSize, ...data }) => {
    return Object.fromEntries(
      Object.entries(data)
        .filter(
          ([key, value]) =>
            !(
              (key === "selfPrintedDiscount" || key === "price") &&
              value === ""
            )
        )
        .map(([key, value]) =>
          (key === "sequence" ||
            key === "minAmount" ||
            key === "baseAmount" ||
            key === "stepSize") &&
          value === ""
            ? [key, null]
            : [key, value]
        )
    );
  };

  const FormLayout = (props) => {
    return (
      <Row className="g-2">
        <Col>
          <Row className="g-2">
            <Col>
              <FloatingInput
                {...props}
                id="name"
                isRequired
                className="min-width-select"
              />
            </Col>
            <Col>
              <FloatingInput {...props} id="code" className="min-width-input" />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="thumbnailFileId"
                className="min-width-select"
              />
            </Col>

            <div className="mt-n2"></div>

            <Col>
              <FloatingInput
                {...props}
                id="sequence"
                rules={RULES.integer}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="productType"
                options={[
                  "Advertentie",
                  "Demotool",
                  "Direct Marketing",
                  "Downloads",
                  "Email",
                  "Handling",
                  "Huis-aan-Huis folders",
                  "Incentives",
                  "Korting",
                  "Merchandise",
                  "Online communicatie",
                  "Placemats",
                  "POS-materiaal",
                  "Premiums",
                  "Producten",
                  "Productie",
                ]}
                className="min-width-select"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="orderType"
                options={[
                  "campaigns",
                  "catalog",
                  "merchandise",
                  "campañas",
                  "partnerA",
                  "partnerB",
                  "partnerc",
                  "partners",
                ]}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="visible"
                options={["always", "packageOnly", "never"]}
                className="min-width-input"
              />
            </Col>

            <div className="mt-n2"></div>

            <Col>
              <FloatingInput
                {...props}
                id="price"
                rules={RULES.currency}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="selfPrintedDiscount"
                rules={RULES.currency}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="minAmount"
                rules={RULES.integer}
                className="min-width-input"
              />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="stepSize"
                rules={{
                  min: {
                    value: 1,
                    message: "Can't be below 1",
                  },
                }}
                className="min-width-input"
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <FloatingInput
            {...props}
            id="description"
            as="textarea"
            className="stretch-height"
          />
        </Col>
      </Row>
    );
  };

  return (
    <DetailPage
      baseUrl={`${config.api.order}/products`}
      path={[{ url: "/products", title: "Products" }]}
      itemLabel={"product"}
      formLayout={FormLayout}
      beforeSumbit={beforeSumbit}
    />
  );
};

export default ProductDetail;

// id	10049 - readonly

// thumbnailFileId	"89e95b5c-f386-4130-bc9e-027f85a449d2" - file upload -> hidden text field

// name	"Korting in verband met zelf printen" - text
// code	"Korting in verband met zelf printen" - short text
// description	"Korting in verband met zelf printen.....\n2e regel..." - multiline text

// programId - filled by selecting a value in another search/select field - required
// mailMomentId - filled by selecting a value in another search/select field
// segmentId - filled by selecting a value in another search/select field
// treatmentId - filled by selecting a value in another search/select field
// manualVariantId - text (int)

// productType	"Direct Marketing" - select, static values
// orderType	"campaigns"  - select, static values
// visible - select, static values
// sequence	- integer

// selfPrintedDiscount	- double - not null
// baseAmount - integer
// stepSize	- integer - not null
// minAmount- integer
// price	- double

// VISIBLE
// always
// packageOnly
// never
