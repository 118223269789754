import React from "react";
import spinner from "../../assets/spinner.svg";

const Loader = ({
  fullPage = false,
  pageWithHeader = false,
  text = "",
  overlay = false,
  viewData = false,
}) => {
  return (
    <div
      className={`loader fade-in ${fullPage ? "full-page" : ""} ${
        pageWithHeader ? "page-with-header" : ""
      } ${
        overlay
          ? "position-absolute inset-0 bg-light bg-opacity-50 z-index-2"
          : ""
      }`}
      style={
        viewData
          ? {
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              width: "100%",
            }
          : {}
      }
    >
      <img src={spinner} alt="" className="" />
      {text ? <div>{text}</div> : null}
    </div>
  );
};

export default Loader;

/*
import React from "react";
import spinner from "../../assets/spinner.svg";

const Loader = ({ fullPage = false, pageWithHeader = false, text = "" }) => {
  return (
    <div
      className={`loader fade-in ${fullPage ? "full-page" : ""} ${
        pageWithHeader ? "page-with-header" : ""
      }`}
    >
      <img src={spinner} alt="" />
      {text ? <div>{text}</div> : null}
    </div>
  );
};

export default Loader;


*/
