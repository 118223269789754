import React from "react";
import { helpSection } from "../../content/helpSection";
import DOMPurify from "dompurify";

const HelpSection = () => {
  return (
    <div>
      {helpSection.map((item) => (
        <div key={item.id}>
          <h3>{item.title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.text, {
                USE_PROFILES: { html: true },
              }),
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default HelpSection;
