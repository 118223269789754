import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../utils/useFetch";
import { config } from "../../../../config";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import { Card, Col, Row } from "react-bootstrap";
import CustomerContactDetails from "../CustomerContactPersons/CustomerContactDetails";
import CustomerSuppliersAddresses from "./CustomerSuppliersAddresses";
import CustomerSuppliersForm from "./CustomerSuppliersForm";
import { useAuthContext } from "../../../../context/AuthContext";
import fetcher from "../../../../utils/fetcher";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import BtnWithDialog from "../../../ui/BtnWithDialog";

const CustomerDetailsSupplier = () => {
  const { customerId } = useParams();
  const { setToasts, client } = useAuthContext();
  const [error, setError] = useState(null);

  const {
    data: suppliers,
    error: suppliersError,
    mutate: refreshSuppliers,
  } = useFetch({
    url: `${config.api.phoenix}/customers/${customerId}/suppliers?pageSize=30`,
    customerId: customerId,
  });

  const { data: suppliersTypes, error: suppliersTypesError } = useFetch({
    url: `${config.api.phoenix}/suppliers/types`,
  });

  const { data: contactTypes, error: contactTypesError } = useFetch({
    url: `${config.api.phoenix}/persons/ContactDetailTypes`,
  });

  const handleDelete = (id) => {
    fetcher({
      url: `${config.api.phoenix}/suppliers/${id}`,
      method: "DELETE",
      payload: {},
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refreshSuppliers();
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to delete supplier",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <WithLoaderAndError
      isLoading={!suppliers || !suppliersTypes || !contactTypes}
      error={suppliersError || suppliersTypesError || contactTypesError}
    >
      <Row className="g-3">
        {!!suppliers &&
          suppliers.map((item) => (
            <Col key={item.id} md={6} lg={6} xl={4} xxl={4} xxxl={3}>
              <Card className="bg-dark bg-opacity-10 shadow-sm mb-3">
                <Card.Header className="bg-cust-color text-white">
                  <Card.Title>{item.name}</Card.Title>
                </Card.Header>
                <Card.Body style={{ minHeight: "9em", paddingBottom: "0px" }}>
                  {!!item.id && (
                    <>
                      {" "}
                      <strong>Supplier ID:</strong> {item.id}
                    </>
                  )}

                  {!!item.description && (
                    <>
                      {" "}
                      <br /> <strong>Description:</strong> {item.description}
                    </>
                  )}
                  {!!item.supplierType && (
                    <>
                      {" "}
                      <br />
                      <strong>Supplier Type:</strong> {item.supplierType}
                    </>
                  )}
                </Card.Body>
                <Card.Body
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "0px",
                  }}
                >
                  {!!item.contactDetails.length && (
                    <div className="mt-auto text-right">
                      <CustomerContactDetails item={item} />
                    </div>
                  )}
                  {!!item.addresses.length && (
                    <div className="mt-auto text-right">
                      <CustomerSuppliersAddresses item={item} />
                    </div>
                  )}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end align-items-center">
                  <CustomerSuppliersForm
                    formData={item}
                    onSucces={() => refreshSuppliers()}
                    suppliersTypes={suppliersTypes}
                    contactTypes={contactTypes}
                  />
                  <BtnWithDialog
                    title={"Delete this supplier"}
                    children={"Are you sure you want to delete this supplier?"}
                    variant="link"
                    onConfirm={() => handleDelete(item.id)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
      <div className="text-right" style={{ marginTop: "0.5rem" }}>
        <CustomerSuppliersForm
          onSucces={() => refreshSuppliers()}
          suppliersTypes={suppliersTypes}
          contactTypes={contactTypes}
        />
      </div>
    </WithLoaderAndError>
  );
};

export default CustomerDetailsSupplier;
