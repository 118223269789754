import React, { useEffect, useState } from "react";
import ImgFileIdInput from "../../../ui/ImgFileIdInput";
import { config } from "../../../../config";
import { useForm } from "react-hook-form";
import fetcher from "../../../../utils/fetcher";
import { useAuthContext } from "../../../../context/AuthContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import uploadFile from "../../../../utils/fetcherUploadFile";
import { BsCheck2, BsTrash, BsX } from "react-icons/bs";

const CustomerMarketingProfileLogos = ({ mp, mpId, refreshMp }) => {
  const { setToasts, client } = useAuthContext();
  const { customerId } = useParams();

  const [error, setError] = useState(null);
  const [logo, setLogo] = useState(mp?.images?.logo || null);
  const [team, setTeam] = useState(mp?.images?.team || null);
  const [route, setRoute] = useState(mp?.images?.route || null);
  const [location, setLocation] = useState(mp?.images?.location || null);

  const [selectedRemoveType, setSelectedRemoveType] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const dataUrl = `${config.api.phoenix}/marketingprofiles/${mpId}/images`;

  const defaultValues = {
    logoFileId: logo?.fileId || "",
    teamFileId: team?.fileId || "",
    routeFileId: route?.fileId || "",
    locationFileId: location?.fileId || "",
    logoFileName: logo?.fileName || "",
    teamFileName: team?.fileName || "",
    routeFileName: route?.fileName || "",
    locationFileName: location?.fileName || "",
  };

  useEffect(() => {
    if (!!mp && !!mp.images && !!mp.images.logo) {
      setLogo(mp.images.logo);
    }
    if (!!mp && !!mp.images && mp.images.logo) {
      setTeam(mp.images.team);
    }
  }, [mp]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to upload or submit image",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!!mp && !!mp.images) {
      reset({
        logoFileId: mp.images.logo?.fileId || "",
        teamFileId: mp.images.team?.fileId || "",
        routeFileId: mp.images.route?.fileId || "",
        locationFileId: mp.images.location?.fileId || "",
        logoFileName: mp.images.logo?.fileName || "",
        teamFileName: mp.images.team?.fileName || "",
        routeFileName: mp.images.route?.fileName || "",
        locationFileName: mp.images.fileName || "",
      });
      setLogo(mp.images.logo);
      setTeam(mp.images.team);
      setRoute(mp.images.route);
      setLocation(mp.images.location);
    }
  }, [mp, reset]);

  //this function does the actual submitting but is used 2 times in this case
  const processFileUpload = (payload) => {
    fetcher({
      url: `${dataUrl}`,
      method: "PUT",
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        refreshMp();
      })
      .catch((err) => {
        setError(err);
      });
  };

  /*
  For each ImgFileInput component there has to be a custom onSubmit function
  */
  const onSubmit = (data) => {
    if (!!data.type && !!data.fileName && !!data.fileId) {
      //for when uploading the actual file
      const payload = {
        ...mp.images,
        [data.type]: {
          target: data.type,
          fileName: data.fileName,
          fileId: data.fileId,
        },
      };
      processFileUpload(payload);
    }
  };

  /*
    This function handles the uploading of a file. uploadFile sends it to the API

  */
  const handleOnFileUpload = (file, type) => {
    const format = file.name.split(".").pop();
    const allowedFormats = ["png", "jpg", "jpeg", "psd", "eps"];
    const fileName = file.name;
    const fileCategory = "CustomerUpload";
    const url = `${config.api.files}/Upload`;

    if (!allowedFormats.includes(format)) {
      setError(
        "Unsupported file format. Allowed formats are: PNG, JPG, JPEG, PSD, EPS."
      );
      return;
    }
    uploadFile({
      url: url,
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
      file: file,
      customerId: customerId,
      clientId: client?.id,
      onSuccess: (data) => {
        onSubmit({
          type: type,
          fileName: file.name,
          fileId: data.fileId,
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  const formProps = {
    watch,
    reset,
    register,
  };

  const handleRemoveImage = (type) => {
    const payload = {
      ...mp.images,
      [type]: {
        target: type,
        fileName: null,
        fileId: null,
      },
    };
    processFileUpload(payload);
  };

  return (
    <Card className="mb-3 bg-dark bg-opacity-10">
      <Card.Header>
        <h2>Logo's</h2>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col>
              <Row className="g-4">
                <Col className="d-flex justify-content-center w-100">
                  <div className="w-100">
                    <div className="d-flex justify-content-center w-100">
                      <h4 style={{ fontSize: "25px" }} className="text-muted">
                        Logo
                      </h4>
                    </div>
                    <div className="img-wrap-marketingprofile">
                      <Badge
                        className="font-monospace position-absolute top-0 translate-middle-y z-index-1 bg-primary-tint-50 fs-85 floating-label-badge"
                        bg={null}
                      >
                        {logo?.fileName}
                      </Badge>
                      <ImgFileIdInput
                        id={"logoFileId"}
                        label={"Logo"}
                        formProps={formProps}
                        handleOnFileUpload={(file) =>
                          handleOnFileUpload(file, "logo")
                        }
                        imageContent={logo}
                        defaultValues={defaultValues}
                        resizeMode="contain"
                        imgBg="rgba(255,255,255,.7)"
                        padding={6}
                      />

                      <div className="remove-button">
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setSelectedRemoveType("Logo");
                            setShowRemoveModal(true);
                          }}
                          className="rounded-circle"
                          disabled={!logo}
                        >
                          <BsTrash color="white" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "25px" }}>
                <Col xs={12} md={4} sm={4}>
                  <div>
                    <div className="d-flex justify-content-center w-100">
                      <h4 style={{ fontSize: "20px" }} className="text-muted">
                        Team
                      </h4>
                    </div>
                    <div className="img-wrap-marketingprofile">
                      <Badge
                        className="font-monospace position-absolute top-0 translate-middle-y z-index-1 bg-primary-tint-50 fs-70 floating-label-badge"
                        bg={null}
                      >
                        {team?.fileName}
                      </Badge>
                      <ImgFileIdInput
                        id="teamFileId"
                        label={"Team"}
                        url={dataUrl}
                        formProps={formProps}
                        handleOnFileUpload={(file) =>
                          handleOnFileUpload(file, "team")
                        }
                        imageContent={team}
                        defaultValues={defaultValues}
                        resizeMode="contain"
                        imgBg="rgba(255,255,255,.7)"
                        padding={4}
                      />
                      <div className="remove-button">
                        <Button
                          onClick={() => {
                            setSelectedRemoveType("Team");
                            setShowRemoveModal(true);
                          }}
                          size="sm"
                          variant="danger"
                          className="rounded-circle"
                          disabled={!team}
                        >
                          <BsTrash color="white" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} sm={4}>
                  <div className="d-flex justify-content-center w-100">
                    <h4 style={{ fontSize: "20px" }} className="text-muted">
                      Location
                    </h4>
                  </div>
                  <div className="img-wrap-marketingprofile">
                    <Badge
                      className="font-monospace position-absolute top-0 translate-middle-y z-index-1 bg-primary-tint-50 fs-70 floating-label-badge"
                      bg={null}
                    >
                      {location?.fileName}
                    </Badge>
                    <ImgFileIdInput
                      id="locationFileId"
                      label={"Location"}
                      url={dataUrl}
                      formProps={formProps}
                      handleOnFileUpload={(file) =>
                        handleOnFileUpload(file, "location")
                      }
                      imageContent={location}
                      defaultValues={defaultValues}
                    />
                    <div className="remove-button">
                      <Button
                        onClick={() => {
                          setSelectedRemoveType("Location");
                          setShowRemoveModal(true);
                        }}
                        size="sm"
                        variant="danger"
                        className="rounded-circle"
                        disabled={!location}
                      >
                        <BsTrash color="white" />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs={11} md={4} sm={4}>
                  <div className="d-flex justify-content-center w-100">
                    <h4 style={{ fontSize: "20px" }} className="text-muted">
                      Route
                    </h4>
                  </div>

                  <div className="img-wrap-marketingprofile">
                    <Badge
                      className="font-monospace position-absolute top-0 translate-middle-y z-index-1 bg-primary-tint-50 fs-70 floating-label-badge"
                      bg={null}
                    >
                      {route?.fileName}
                    </Badge>
                    <ImgFileIdInput
                      id="routeFileId"
                      label={"Route"}
                      url={dataUrl}
                      formProps={formProps}
                      handleOnFileUpload={(file) =>
                        handleOnFileUpload(file, "route")
                      }
                      imageContent={route}
                      defaultValues={defaultValues}
                    />
                    <div className="remove-button">
                      <Button
                        onClick={() => {
                          setSelectedRemoveType("Route");
                          setShowRemoveModal(true);
                        }}
                        size="sm"
                        variant="danger"
                        className="rounded-circle"
                        disabled={!route}
                      >
                        <BsTrash color="white" />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      <Modal
        style={{ marginTop: "150px" }}
        size="lg"
        show={showRemoveModal}
        onHide={() => {
          setShowRemoveModal(false);
          setSelectedRemoveType(null);
        }}
      >
        <Modal.Header>
          <h4>{`Remove ${selectedRemoveType}`}</h4>
        </Modal.Header>
        <Modal.Body>
          {selectedRemoveType === ""}
          {`Are you sure you want to remove ${
            selectedRemoveType === "Team"
              ? `${team?.fileName}`
              : `${logo?.fileName}`
          }?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              setShowRemoveModal(false);
              setSelectedRemoveType(null);
            }}
          >
            <BsX className="btn-icon me-1" /> No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleRemoveImage(selectedRemoveType);
              setShowRemoveModal(false);
            }}
          >
            <BsCheck2 className="btn-icon me-1" /> Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default CustomerMarketingProfileLogos;
