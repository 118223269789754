import { Parser } from "json2csv";

const exportCSV = ({
  data,
  fileName = "",
  parserOptions = {},
  onSuccess = () => null,
  onError = (err) => console.error(err),
}) => {
  try {
    const parser = new Parser(parserOptions);
    const csv = parser.parse(data);
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob(["\uFEFF" + csv], {
        type: "text/csv;charset=utf-8",
      })
    );
    a.download = fileName || data?.id || "CSV";
    a.click();
    onSuccess({ data, fileName });
  } catch (err) {
    onError(err);
  }
};

export default exportCSV;
