import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import useFetch from "../../utils/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import fetcher from "../../utils/fetcher";
import { useForm } from "react-hook-form";
import {
  Card,
  Button,
  Col,
  Form,
  Row,
  Toast,
  ToastContainer,
  Dropdown,
  DropdownButton,
  Badge,
  Stack,
} from "react-bootstrap";
import { BsCheck2, BsDownload, BsPencil, BsX } from "react-icons/bs";
import exportCSV from "../../utils/exportCVS";
import Error from "./Error";
import BtnWithDialog from "./BtnWithDialog";
import { useAuthContext } from "../../context/AuthContext";

const DetailPage = ({
  baseUrl = "",
  path = [],
  itemLabel = "item",
  itemThumbnail = "thumbnailFileId",
  itemImages = [],
  formLayout: FormLayout,
  beforeSumbit = (formValues) => formValues,
  menuType,
  colorClass,
}) => {
  const { id } = useParams();
  const { client } = useAuthContext();
  const navigate = useNavigate();

  const isNew = id === "new";
  const url = isNew ? baseUrl : `${baseUrl}/${id}`;
  const method = isNew ? "POST" : "PUT";

  const {
    data,
    error: fetchError,
    mutate,
  } = useFetch({
    url: url,
    shouldFetch: () => !isNew,
  });

  const [editable, setEditable] = useState(id === "new");
  const enableForm = (e) => !editable && setEditable(true);

  const [submitErrors, setSubmitErrors] = useState([]);

  const defaultValues = !!data ? data : {};

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    !!data && reset(data);
  }, [data, reset]);

  const onCancel = () => {
    if (isNew && !!path?.[0]?.url) {
      navigate(path?.[0]?.url || "/");
    } else {
      reset({ ...defaultValues });
      setEditable(false);
    }
  };

  const onSubmit = (formValues) => {
    fetcher({
      url: url,
      method: method,
      clientId: client?.id || null,
      payload: beforeSumbit(formValues),
      customerId: data?.customerId || null,
    })
      .then((res) => {
        setEditable(false);
        if (isNew && res?.data?.id && path?.[0]?.url) {
          navigate(`${path?.[0]?.url}/${res?.data?.id}`);
        } else {
          mutate();
        }
      })
      .catch((err) => {
        setSubmitErrors([
          {
            ...err,
            show: true,
            action: isNew ? "create" : "update",
            autohideToast: true,
            errorActionNavigateTo: path?.[0] || null,
          },
          ...submitErrors,
        ]);
      });
  };

  const onDelete = () => {
    if (!isNew) {
      fetcher({
        url: url,
        method: "DELETE",
        clientId: client?.id || null,
        customerId: data?.customerId || null,
      })
        .then((res) => {
          setEditable(false);
          navigate(`${path?.[0]?.url}` || "/");
        })
        .catch((err) => {
          setSubmitErrors([
            {
              ...err,
              show: true,
              action: "delete",
              autohideToast: true,
              errorActionNavigateTo: path?.[0] || null,
            },
            ...submitErrors,
          ]);
        });
    }
  };

  const ExportBtn = () => (
    <DropdownButton
      size="sm"
      title={
        <>
          <BsDownload className="me-1" /> Export
        </>
      }
      variant="outline-dark"
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown.Item
        as="button"
        type="button"
        disabled={!data}
        onClick={(e) => {
          e.stopPropagation();
          exportCSV(data, `${itemLabel} ${id}`);
        }}
      >
        Export CSV
      </Dropdown.Item>
      <Dropdown.Item
        as="button"
        type="button"
        disabled={!data}
        onClick={(e) => {
          e.stopPropagation();
          !!navigator &&
            navigator.clipboard.writeText(JSON.stringify({ data }));
        }}
      >
        Copy JSON to clipboard
      </Dropdown.Item>
    </DropdownButton>
  );

  return (
    <Layout
      pageTitle={data?.name || `New ${itemLabel}`}
      path={path}
      rightBlock={
        !isNew ? (
          <Badge
            pill
            className="ms-3 badge pill bg-secondary-tint-25 text-light font-monospace"
          >
            <span className="opacity-75">ID:</span> {data?.id}
          </Badge>
        ) : null
      }
      menuType={menuType}
      colorClass={colorClass}
    >
      <WithLoaderAndError
        isLoading={!data && !fetchError && !isNew}
        error={fetchError}
        fullpage
        navigateTo={path?.[0] || null}
      >
        {data || isNew ? (
          <>
            <Form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              onReset={() => reset({ ...defaultValues })}
              onClick={enableForm}
            >
              <Card
                className="fixed-height-card"
                style={{ background: "var(--bs-gray-200)" }}
              >
                <Card.Header>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="justify-content-end flex-wrap cancel-card-footer-padding"
                  >
                    <ExportBtn />
                    <BtnWithDialog
                      onConfirm={onDelete}
                      btnSize="sm"
                      isDisabled={!editable || isNew}
                    >
                      <div className="py-3 text-center">
                        Delete{" "}
                        {<strong>{data?.name}</strong> ||
                          `${itemLabel} ${(
                            <span className="font-monospace">{id}</span>
                          )}`}
                        ?
                      </div>
                    </BtnWithDialog>
                  </Stack>
                </Card.Header>
                <Card.Body>
                  <Row className="g-2">
                    {/* <Col xs="auto">
                      <ImgBox
                        imgId={isNew ? null : data?.[itemThumbnail]}
                        width={300}
                        height={300}
                        className={`border input-border rounded ${
                          editable ? "bg-white" : ""
                        }`}
                        style={{ minWidth: "300px" }}
                      />
                      {itemImages?.length ? (
                        <Row className="g-2 pt-2">
                          {itemImages.map((itemImageKey) => (
                            <Col key={itemImageKey}>
                              <ImgBox
                                imgId={isNew ? null : data?.[itemImageKey]}
                                width={100}
                                height={100}
                                className={`border input-border rounded ${
                                  editable ? "bg-white" : ""
                                }`}
                              />
                            </Col>
                          ))}
                        </Row>
                      ) : null}
                    </Col> */}
                    <Col>
                      <FormLayout
                        register={register}
                        errors={errors}
                        editable={editable}
                        isSubmitted={isSubmitted}
                        enableForm={enableForm}
                        watch={watch}
                      />

                      <ToastContainer
                        containerPosition="fixed"
                        position="bottom-end"
                        className="is-fixed"
                      >
                        {submitErrors.map((err, i) => (
                          <Toast
                            onClose={() =>
                              setSubmitErrors((prevSubmitErrors) =>
                                prevSubmitErrors.map((item, itemIndex) =>
                                  itemIndex === i
                                    ? { ...item, show: false }
                                    : item
                                )
                              )
                            }
                            show={err?.show}
                            delay={10000}
                            autohide={err?.autohideToast}
                            key={i}
                          >
                            <Toast.Header>
                              <strong className="me-auto">
                                Failed to {err.action} {itemLabel}
                              </strong>
                              <small className="text-muted">{err.code}</small>
                            </Toast.Header>
                            <Toast.Body>
                              <Error
                                error={err}
                                setErrors={setSubmitErrors}
                                errorIndex={i}
                                navigateTo={err?.errorActionNavigateTo || null}
                              />
                            </Toast.Body>
                          </Toast>
                        ))}
                      </ToastContainer>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="sticky-bottom card-header-light-gray">
                  <div className="d-flex justify-content-end cancel-card-footer-padding">
                    {editable ? (
                      <>
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={onCancel}
                          className="me-1"
                        >
                          <BsX className="btn-icon me-1" /> Cancel
                        </Button>{" "}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={!isDirty}
                        >
                          <BsCheck2 className="btn-icon me-1" /> Save changes
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="button"
                        variant="success"
                        onClick={() => setEditable(true)}
                      >
                        <BsPencil className="btn-icon me-1" /> Edit
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </Form>
          </>
        ) : null}
      </WithLoaderAndError>
    </Layout>
  );
};

export default DetailPage;
