import React, { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import {
  BsFileEarmarkRuled,
  BsHouse,
  BsMailbox,
  BsShop,
  BsTruck,
} from "react-icons/bs";

const CustomerSuppliersAddresses = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        variant="flush"
        onClick={() => setShowModal(true)}
        style={{ textDecoration: "underline" }}
      >
        <BsHouse />
        <strong>{" View Addresses"}</strong>
      </Button>
      <div style={{ width: "200px" }}>
        {" "}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Supplier Address Detaills </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row xs={1} md={2} className="g-3">
              {item.addresses.length
                ? item.addresses.map((detail, index) => (
                    <Col key={index}>
                      <Card
                        className="bg-dark bg-opacity-10"
                        style={{ minHeight: "15em" }}
                      >
                        <Card.Header>
                          <small
                            className="text-muted d-block py-1"
                            style={{ marginTop: "-.6em" }}
                          >
                            <span className="fs-4">
                              {detail.type === "shop" ? (
                                <BsShop />
                              ) : detail.type === "delivery" ? (
                                <BsTruck />
                              ) : detail.type === "postal" ? (
                                <BsMailbox />
                              ) : detail.type === "invoice" ? (
                                <BsFileEarmarkRuled />
                              ) : (
                                detail.type
                              )}{" "}
                            </span>
                            {detail.type} address
                          </small>
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>{detail.name}</Card.Title>
                          {detail.street} {detail.houseNumber} {detail.suffix}
                          {!!detail.postalCode || !!detail.city ? <br /> : null}
                          {detail.postalCode}
                          {!!detail.postalCode && !!detail.city ? ", " : null}
                          {detail.city}
                          {!!detail.province || !!detail.country ? (
                            <br />
                          ) : null}
                          {detail.province}
                          {!!detail.province && !!detail.country ? ", " : null}
                          {detail.country}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : null}
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CustomerSuppliersAddresses;
