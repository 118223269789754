import React from "react";
import ListPage from "../ui/ListPage";
import { config } from "../../config";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import ItemCard from "../ui/ItemCard";

const Packages = () => {
  const GridItem = ({ item }) => (
    <ItemCard
      {...item}
      imgSize={300}
      itemBadge="products"
      itemPath="packages"
    />
  );

  return (
    <ListPage
      baseUrl={`${config.api.order}/packages`}
      itemLabel="package"
      itemPath="packages"
      gridItem={GridItem}
      tableItems={{
        thumbnailFileId: {},
        id: {},
        code: {
          cellClass: "text-start",
        },
        name: {},
        products: {
          cellClass: "text-center",
          bringToFront: true,
          component: ({ value }) =>
            value.length ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Popover id="packProd">
                    <Popover.Body>
                      <h4 className="fw-semibold fs-6 mb-2">Products</h4>
                      {value.map((item, i) => (
                        <div
                          key={item.productId}
                          className="text-nowrap text-truncate font-monospace mw-100 fs-90 py-1"
                          style={{ borderTop: "2px dotted #ddd" }}
                        >
                          <span className="font-monospace">{item.amount}</span>{" "}
                          <span className="font-monospace text-muted">x</span>{" "}
                          <span>{item.name}</span>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Badge
                  pill
                  className="font-monospace bg-primary-tint-25 position-relative cursor-pointer"
                >
                  {value.length}
                </Badge>
              </OverlayTrigger>
            ) : null,
        },
        price: {},
        description: {},
      }}
      searchParam="searchPhrase"
      orderOptions={["id", "sequence"]}
      pageSizes={[10, 20, 30, 40, 50]}
      defaultLayout={"grid-xl"}
      gridItemBadge="products"
    />
  );
};

export default Packages;
