import React from "react";

const PhoenixIcon = ({ className, ...props }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      width="1em"
      height="1em"
      viewBox="0 0 458.000000 458.000000"
      className={`logo-icon ${className}`}
      {...props}
    >
      <g
        transform="translate(0.000000,458.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M2051 4559 c-324 -31 -700 -167 -986 -356 -526 -348 -876 -864 -1007
      -1485 -32 -152 -33 -156 -33 -433 0 -277 1 -281 33 -433 131 -621 481 -1137
      1007 -1485 571 -378 1280 -466 1943 -242 345 117 594 276 872 556 202 204 336
      387 436 594 288 599 309 1303 57 1895 -182 426 -524 821 -933 1075 -401 248
      -900 361 -1389 314z m529 -134 c280 -43 598 -153 784 -270 253 -159 577 -482
      753 -753 127 -193 236 -507 278 -793 19 -135 19 -367 0 -484 -48 -295 -241
      -731 -404 -915 -49 -55 -49 -52 18 152 84 260 105 374 105 573 1 142 -3 188
      -23 279 -59 277 -174 538 -327 741 -65 87 -209 231 -218 217 -3 -5 23 -68 58
      -140 119 -241 180 -438 211 -682 20 -156 16 -370 -9 -486 -60 -278 -233 -651
      -392 -844 -96 -118 -112 -135 -118 -129 -4 3 36 93 89 200 138 282 139 287
      139 664 1 359 -4 392 -94 600 -87 203 -232 423 -289 438 -24 6 -20 -7 21 -74
      45 -73 85 -169 109 -260 20 -73 23 -108 24 -279 0 -173 -3 -205 -23 -280 -58
      -216 -192 -414 -391 -581 -212 -178 -391 -243 -666 -242 -226 1 -419 61 -610
      188 -240 159 -410 423 -455 705 -14 94 -12 294 5 391 63 361 297 678 574 775
      108 39 162 30 269 -43 78 -54 130 -75 153 -62 46 24 28 162 -37 288 -35 65
      -48 104 -55 161 -24 183 -46 237 -125 309 -29 26 -87 65 -130 86 -94 47 -157
      102 -190 167 -13 26 -28 48 -33 48 -17 0 -40 -38 -51 -85 -7 -28 -16 -66 -21
      -85 -13 -53 -124 -185 -329 -390 -264 -265 -367 -398 -466 -604 -92 -191 -163
      -446 -185 -663 -15 -147 -6 -410 20 -549 39 -210 98 -388 191 -574 162 -324
      383 -573 700 -790 86 -60 118 -99 68 -85 -41 11 -230 105 -322 159 -451 266
      -822 767 -972 1315 -341 1245 539 2540 1836 2700 105 14 405 5 530 -14z"
        />
      </g>
    </svg>
  );
};

export default PhoenixIcon;
