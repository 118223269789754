import React, { useState } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  Offcanvas,
  ListGroup,
} from "react-bootstrap";
import {
  BsArrowUpCircleFill,
  BsBugFill,
  BsHearts,
  BsLightbulbFill,
  BsX,
} from "react-icons/bs";
import { TfiCommentsSmiley } from "react-icons/tfi";
import { useDrawer } from "../../context/DrawerContext";

const FeedbackForm = () => {
  const { showOffCanvas, handleCloseOffCanvas, setShowSelectClient } =
    useDrawer();

  const [body, setBody] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleSendBtnClick = () => {
    setIsSent(true);

    setFeedbackType("");
    setBody("");

    setTimeout(() => {
      setIsSent(false);
      handleCloseOffCanvas();
    }, 1000);
  };

  const mailtoAddress = "phoenixfeedback@llcrm.eu";
  const subject = "Phoenix Web Tool Feedback";
  const bcc = "";

  return (
    <Offcanvas
      show={showOffCanvas}
      onHide={handleCloseOffCanvas}
      onEnter={() => setShowSelectClient(false)}
      placement="start"
      data-bs-theme="dark"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        className="header-inner-height"
      >
        <h5 className="fw-light mb-0">
          <TfiCommentsSmiley
            className="fs-3 opacity-50 align-text-bottom"
            style={{ marginRight: "1rem", marginLeft: ".7rem" }}
          />
          Help improve Phoenix<sup>WEB</sup>
        </h5>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0" style={{ scrollbarWidth: "thin" }}>
        {isSent ? (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <h5 className="fw-light">
              <BsHearts style={{ color: "#925f78" }} /> Thank you!
            </h5>
          </div>
        ) : (
          <div>
            <ListGroup variant="flush" className="border-top border-bottom">
              <ListGroup.Item
                action
                className="d-flex align-items-center justify-content-center"
                active={feedbackType === "issue"}
                onClick={() =>
                  setFeedbackType((curr) => (curr === "issue" ? "" : "issue"))
                }
              >
                <BsBugFill
                  style={{ width: "48px", height: "1.2em" }}
                  className="flex-shrink-0 opacity-75 me-2 text-danger"
                />
                <div className="flex-fill">Report a bug</div>
              </ListGroup.Item>
              <ListGroup.Item
                action
                className="d-flex align-items-center justify-content-center"
                active={feedbackType === "idea"}
                onClick={() =>
                  setFeedbackType((curr) => (curr === "idea" ? "" : "idea"))
                }
              >
                <BsLightbulbFill
                  style={{ width: "48px", height: "1.2em" }}
                  className="flex-shrink-0 opacity-75 me-2 text-warning"
                />
                <div className="flex-fill">Share an idea</div>
              </ListGroup.Item>
              <ListGroup.Item
                action
                className="d-flex align-items-center justify-content-center"
                active={feedbackType === "improvement"}
                onClick={() =>
                  setFeedbackType((curr) =>
                    curr === "improvement" ? "" : "improvement"
                  )
                }
              >
                <BsArrowUpCircleFill
                  style={{ width: "48px", height: "1.2em" }}
                  className="flex-shrink-0 opacity-75 me-2 text-success"
                />
                <div className="flex-fill">Suggest an improvement</div>
              </ListGroup.Item>
            </ListGroup>
            <div className="p-3">
              <Form noValidate>
                <FloatingLabel
                  label="Let us know what you think"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "25vh" }}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </FloatingLabel>
              </Form>
              <Button
                variant="success"
                as="a"
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${mailtoAddress}&su=${subject}${
                  feedbackType ? ": " + feedbackType : ""
                }&body=${body}&bcc=${bcc}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-100"
                onClick={() => handleSendBtnClick()}
              >
                Open Gmail to send
              </Button>
              <ul className="mt-3">
                <li>
                  You can adjust your message, add screenshots and links in the
                  next window
                </li>
                <li>
                  You can also mail your input directly to{" "}
                  <a
                    href={`mailto:${mailtoAddress}?subject=${subject}&bcc=${bcc}&body=${body}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {mailtoAddress}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Offcanvas.Body>
      <Offcanvas.Header
        className="bg-black bg-opacity-25 justify-content-end px-2"
        style={{ height: "var(--footer-height)" }}
      >
        <Button
          variant="link"
          className="hover-parent link-light text-decoration-none"
          onClick={handleCloseOffCanvas}
        >
          <span className="hover-opacity-0">Close</span>
          <BsX className="ms-2 fs-4 align-text-top" />
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default FeedbackForm;
