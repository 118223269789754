import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Col, Form, Row, FloatingLabel, Button } from "react-bootstrap";
import { mutate } from "swr";
import { useForm } from "react-hook-form";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import fetcher from "../../../../utils/fetcher";
import useFetch from "../../../../utils/useFetch";
import { config } from "../../../../config";
import { useAuthContext } from "../../../../context/AuthContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";

const InvoiceCollectivesField = ({
  watch,
  setValue,
  isFieldDirty,
  editable,
}) => {
  const fieldName = "collectives";

  // Watch the current values of the collectives field
  const collectiveValues = watch(fieldName) || [];

  // Determine if MON and ORM are checked
  const isMONChecked = collectiveValues.some((item) => item.name === "MON");
  const isORMChecked = collectiveValues.some((item) => item.name === "ORM");

  // Function to handle checkbox changes
  const handleCheckboxChange = (isChecked, name, id) => {
    let updatedCollectives = [];

    if (isChecked) {
      // If the checkbox is checked, add the corresponding item to the array
      updatedCollectives = [...collectiveValues, { id, name }];
    } else {
      // If the checkbox is unchecked, remove the corresponding item from the array
      updatedCollectives = collectiveValues.filter(
        (item) => item.name !== name
      );
    }

    // Handle the logic to add "None" or specific collective items
    if (updatedCollectives.length === 0) {
      // If no checkbox is checked, add "None"
      updatedCollectives = [{ id: 0, name: "None" }];
    } else {
      // Remove "None" if other items are present
      updatedCollectives = updatedCollectives.filter(
        (item) => item.name !== "None"
      );
    }

    // Set the new value for the collectives field
    setValue(fieldName, updatedCollectives, { shouldDirty: true });
    setValue("collective", null);
  };

  return (
    <>
      <Form.Check
        className="custom-checkbox" // Apply custom class here
        style={
          isFieldDirty(fieldName)
            ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
            : {}
        }
        type="checkbox"
        id={`check-mon-${fieldName}`}
        label="MON"
        checked={isMONChecked}
        onChange={(e) => handleCheckboxChange(e.target.checked, "MON", 1)}
        disabled={!editable}
      />
      <Form.Check
        className="custom-checkbox" // Apply custom class here
        style={
          isFieldDirty(fieldName)
            ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
            : {}
        }
        type="checkbox"
        id={`check-orm-${fieldName}`}
        label="ORM"
        checked={isORMChecked}
        onChange={(e) => handleCheckboxChange(e.target.checked, "ORM", 2)}
        disabled={!editable}
      />
    </>
  );
};

const CustomerDetailsInvoicing = () => {
  const { customerId } = useParams();
  const { client, setToasts } = useAuthContext();
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(false);

  const dataUrl = `${config.api.invoicing}/customers/${customerId}`;

  const refetch = () => {
    mutate(invoiceKey);
  };

  const {
    data: invoiceSettings,
    error: invoiceError,
    key: invoiceKey,
  } = useFetch({
    url: dataUrl,
    customerId: customerId,
    clientId: client?.id,
  });

  // Initialize the form with default values if invoiceSettings is undefined
  const defaultValues = {
    collective: "None",
    collectives: [],
    isPremium: false,
    premiumSponsored: false,
    defaultMonMaxCount: 0,
  };

  const {
    reset,
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields, isDirty },
    setValue,
  } = useForm({
    defaultValues: invoiceSettings || defaultValues,
  });

  const isFieldDirty = (fieldName) => dirtyFields[fieldName];

  const onSubmit = (data) => {
    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: data,
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refetch();
        setEditable(false);
      })
      .catch((err) => {
        setError(err);
        setEditable(false);
      });
    reset(data);
  };

  // Handle errors
  useEffect(() => {
    if (invoiceError && invoiceError?.message !== "Network Error") {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Error message for Invoice Settings",
          delay: 50000,
          text: createDetailedErrorMessage(invoiceError),
        },
      ]);
    }
    if (error) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Error message for Invoice Settings",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts, invoiceError]);

  useEffect(() => {
    if (invoiceSettings) {
      reset(invoiceSettings);
    }
  }, [invoiceSettings, reset]);

  return (
    <Card className="mb-3 bg-dark bg-opacity-10 my-3">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Title style={{ margin: "10px" }}>Invoice settings</Card.Title>
        <Card.Body>
          <Row className="g-3 mb-3">
            <Form.Check
              style={
                isFieldDirty("isPremium")
                  ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                  : {}
              }
              type="switch"
              id={`switch-isPremium`}
              label="Is premium member"
              {...register("isPremium")}
              disabled={!editable}
            />
          </Row>
          <Row className="g-3 mb-3">
            <Form.Check
              style={
                isFieldDirty("premiumSponsored")
                  ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                  : {}
              }
              type="switch"
              id={`switch-premiumSponsored`}
              label="Is premium sponsored"
              {...register("premiumSponsored")}
              disabled={!editable}
            />
          </Row>

          <Row className="g-3 mb-3">
            <Card style={{ backgroundColor: "primary" }}>
              <Card.Body>
                <Row>
                  <Col>Member of Collective</Col>
                  <Col>
                    <InvoiceCollectivesField
                      watch={watch}
                      setValue={setValue}
                      isFieldDirty={isFieldDirty}
                      editable={editable}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>

          <Row className="g-3 mb-3">
            <FloatingLabel
              controlId={"defaultMonMaxCount"}
              className="floating-label"
              label="Max DM count for Collective"
            >
              <Form.Control
                type="number"
                {...register("defaultMonMaxCount")}
                isInvalid={errors["defaultMonMaxCount"]}
                min={0}
                max={9999}
                disabled={!editable}
                step="1"
                placeholder={0}
              />
              <Form.Control.Feedback type="invalid">
                {errors["defaultMonMaxCount"] &&
                  errors["defaultMonMaxCount"].message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Row>
        </Card.Body>
        {editable ? (
          <div className="text-end">
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                reset(invoiceSettings || defaultValues);
                setEditable(false);
              }}
            >
              <BsX className="btn-icon me-1" /> Cancel
            </Button>
            <Button variant="success" type="submit" disabled={!isDirty}>
              <BsCheck2 className="btn-icon me-1" /> Save changes
            </Button>
          </div>
        ) : (
          <div className="text-end">
            <Button
              type="button"
              variant="success"
              onClick={() => setEditable(true)}
            >
              <BsPencil className="btn-icon me-1" /> Edit
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
};

export default CustomerDetailsInvoicing;
