import { BsList } from "react-icons/bs";
import { config } from "../../config";
import PhoenixIcon from "./PhoenixIcon";

const PhoenixLogo = ({
  variant = "white-highlight",
  layout = "horizontal",
  logoStyle = "",
  className = "",
  ...props
}) => {
  return (
    <div className={`px-logo ${variant} ${layout} ${logoStyle} ${className}`}>
      <span className="icon-wrap">
        <PhoenixIcon {...props} />
        <BsList className="btn-icon" />
      </span>
      <span className="logo-text">
        {config.name.title}
        <sup>{config.name.subTitle}</sup>
      </span>
    </div>
  );
};

export default PhoenixLogo;
