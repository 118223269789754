import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const PageHeading = ({
  pageTitle,
  subhead = "",
  path,
  xl = false,
  fixedHeight = false,

  rightBlock = null,
  children,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-start align-items-baseline p-2 fs-85">
        <Breadcrumbs activePageTitle={pageTitle} path={path} />
        {rightBlock}
      </div>
      <div className={`page-head ${fixedHeight ? "fixed-height" : ""}`}>
        <div className="h1">
          <h1 className={`${xl ? " display-1" : ""}${subhead ? " mb-1" : ""}`}>
            {pageTitle}
          </h1>
          {subhead ? <p className="lead mb-0">{subhead}</p> : null}
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageHeading;
