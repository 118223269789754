import React from "react";
import { config } from "../../../../config";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import FormComponent from "../../../ui/FormComponent";
import CustomerDetailsStatus from "./CustomerDetailsStatus";
import CustomerDetailsInvoicing from "./CustomerDetailsInvoicing";
import CustomerDetailsLabels from "./CustomerDetailsLabels";

const CustomerDetailsGeneral = ({
  customer,
  formSettings,
  refreshCustomer,
  mp,
}) => {
  //parameters that are initialized for FormComponent
  const dataUrl = `${config.api.phoenix}/customers/${customer?.id}`;
  const method = "PUT";

  return (
    <Row className="g-3 mb-3">
      <Col>
        <FormComponent
          dataUrl={dataUrl}
          method={method}
          refetch={() => refreshCustomer()}
          content={customer}
          formSettings={formSettings}
        >
          <FormComponent.DirtyFields />
          <Card className="mb-3 bg-dark bg-opacity-10">
            <CardBody>
              <Row className="g-3 mb-3">
                <Col>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="name"
                      formSettings={formSettings}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="id"
                      formSettings={formSettings}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="code"
                      formSettings={formSettings}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="salesManager"
                      formSettings={formSettings}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="projectManager"
                      formSettings={formSettings}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="language"
                      formSettings={formSettings}
                      type="select"
                    />
                  </Row>
                </Col>

                <Col>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="description"
                      formSettings={formSettings}
                      type="textarea"
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <FormComponent.Field
                      fieldName="openingTimes"
                      formSettings={formSettings}
                      type="textarea"
                    />
                  </Row>
                </Col>
              </Row>
              <FormComponent.EditButton formSettings={formSettings} />
            </CardBody>
          </Card>
        </FormComponent>
      </Col>
      <Col sm={3}>
        <CustomerDetailsStatus
          customer={customer}
          dataUrl={dataUrl}
          onHold={customer?.onHold}
          onHoldBy={customer?.onHoldBy}
          onHoldDescription={customer?.onHoldDescription}
          formSettings={formSettings}
          refetch={() => refreshCustomer()}
          mp={mp}
        />
        <CustomerDetailsInvoicing />
        <FormComponent
          dataUrl={dataUrl}
          method={method}
          refetch={() => refreshCustomer()}
          content={customer}
          formSettings={formSettings}
        >
          <CustomerDetailsLabels
            formSettings={formSettings}
            customer={customer}
            type="internallabels"
          />
          <CustomerDetailsLabels
            formSettings={formSettings}
            customer={customer}
            type="externallabels"
          />
          <FormComponent.EditButton formSettings={formSettings} />
        </FormComponent>
      </Col>
    </Row>
  );
};

export default CustomerDetailsGeneral;
