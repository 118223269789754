import { config } from "../config";
import encodeParams from "./encodeParams";

export const getImgUrl = (
  id,
  { width, height, format, resizeMode },
  api = `${config.api.images}/images`
) => {
  const widthP = width ? { width } : {};
  const heightP = height ? { height } : {};
  const formatP = format ? { format } : {};
  const resizeModeP = resizeMode ? { resizeMode } : {};

  const paramsWithoutNulls = {
    ...widthP,
    ...heightP,
    ...formatP,
    ...resizeModeP,
  };

  const paramsStr = encodeParams(paramsWithoutNulls);

  return id ? `${api}/${id}${paramsStr}` : "";
};
