import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsPhone } from "react-icons/bs";

const CustomerContactDetails = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        variant="flush"
        onClick={() => setShowModal(!showModal)}
        style={{ textDecoration: "underline" }}
      >
        <strong>
          <BsPhone /> {" View Contact Details"}
        </strong>
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {item.contactDetails.length ? (
            item.contactDetails.map((detail, index) => (
              <p key={index}>
                <strong>{detail.type}:</strong> {detail.value}
              </p>
            ))
          ) : (
            <p>No contact details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerContactDetails;
