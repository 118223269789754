import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { useParams } from "react-router";
import { config } from "../../../config";
import { useAuthContext } from "../../../context/AuthContext";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import SelectCustomerDrawer from "../../custom/SelectCustomerDrawer";
import FavoriteButton from "../../custom/FavoriteButton";
import DOMPurify from "dompurify";
import MenuOnDetail from "../../ui/MenuOnDetail";
import PaginationALDetail from "./PaginationALDetail";
import HeaderALDetail from "./HeaderALDetail";
import TableALDetail from "./TableALDetail";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import useFetch from "../../../utils/useFetch";

const ALDetail = () => {
  const { reportId, viewId } = useParams();
  const { client, setToasts, setClient } = useAuthContext();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(
    sessionStorage.getItem(`page_${reportId}_${viewId}`)
      ? parseInt(sessionStorage.getItem(`page_${reportId}_${viewId}`), 10)
      : 1
  );
  const PAGE_SIZE = 100;
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSelectCustomer, setShowSelectCustomer] = useState(true);
  const [filter, setFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(
    sessionStorage.getItem(`selectedFilters_${reportId}_${viewId}`)
      ? JSON.parse(
          sessionStorage.getItem(`selectedFilters_${reportId}_${viewId}`)
        )
      : []
  );
  const [actionOption, setActionOption] = useState(null);
  const [isViewExpanded, setIsViewExpanded] = useState(false);
  const [viewData, setViewData] = useState(null);

  //request view
  const {
    data: view,
    error: fetchViewError,
    isValidating: viewIsValidating,
  } = useFetch({
    url: `${config.api.reporting}/views/${reportId}/${viewId}`,
    clientId: client?.id,
    customerId: client?.customer?.id,
    shouldFetch: () => !!client?.customer,
  });

  //request viewData
  const {
    data: dataview,
    error: fetchDataError,
    isValidating: dataIsValidating,
    mutate: refreshViewData,
  } = useFetch({
    url: `${config.api.reporting}/views/${reportId}/data?page=${page}&pageSize=${PAGE_SIZE}${filter}&search=${query}`,
    clientId: client?.id,
    customerId: client?.customer?.id,
    shouldFetch: () => !!client.customer && !!view,
    revalidateOnReconnect: true,
  });

  const parentPage = {
    title: "Action lists",
    url: `/reports/actionlist`,
  };

  /*
    derives values of selectedFilters and page from sessionStorage
  */
  useEffect(() => {
    sessionStorage.setItem(
      `selectedFilters_${reportId}_${viewId}`,
      JSON.stringify(selectedFilters)
    );
    sessionStorage.setItem(`page_${reportId}_${viewId}`, page.toString());
  }, [page, selectedFilters, reportId, viewId]);

  /*
    checks if customer has already been chosen. If not SelectCustomerDrawer pops up
  */
  useEffect(() => {
    if (client?.customer === null) {
      setShowSelectCustomer(true);
    } else {
      setShowSelectCustomer(false);
    }
  }, [client, setClient]);

  /*
    if an error appears from fetching viewData, a toast is made
  */
  useEffect(() => {
    if (fetchDataError) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to fetch viewData",
          delay: 50000,
          text: createDetailedErrorMessage(fetchDataError),
        },
      ]);
    } else {
      refreshViewData();
    }
  }, [client?.customer, fetchDataError, refreshViewData, setToasts]);

  /*
    updates the actual viewData that is seen in the table 
    once the viewData has been fetched
    this causes the viewData not to disappear between 
    loading and updating only when all data has been fetched
  */
  useEffect(() => {
    if (!dataIsValidating && !fetchDataError && dataview != null) {
      setViewData(dataview);
    }
  }, [dataIsValidating, dataview, fetchDataError]);

  /*
    first checks if this is first mount, if so we skip this so that the program has
    more time to fetch the filter from the sessionstorage if in the same session.
    Handles filter logic
  */
  const isInitialLoad = useRef(true);
  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    } else {
      if (view && selectedFilters) {
        const filterableColumns = !!view
          ? view?.chart?.columns
              .filter((item) => !!item.filter)
              .map((item) => item.id)
          : [];
        const pageFilters = selectedFilters.filter((item) =>
          filterableColumns.includes(item.colId)
        );
        const filterStr =
          pageFilters.length > 0
            ? "&" +
              pageFilters
                .map((item) =>
                  item.filters
                    .map(
                      (filter) =>
                        `filter=${encodeURI(item.colId)}:${encodeURI(filter)}`
                    )
                    .join("&")
                )
                .join("&")
            : "";
        setFilter(filterStr);
        setPage(1);
      }
    }
  }, [selectedFilters, view]);

  /*
    breadcrumbs that show path of the page
  */
  const getReportBreadcrumbs = (viewPath, parentPage) => {
    if (!viewPath || viewPath === "/") {
      return [];
      // if (!parentPage) {
      //   return [];
      // } else {
      //   return [parentPage];
      // }
    }
    const trimmedStr =
      viewPath.indexOf("/") === 0 ? viewPath.replace("/", "") : viewPath;
    return [
      // parentPage,
      ...trimmedStr.split("/").map((item) => ({ title: item })),
    ];
  };

  /*
    Calculates available action options based on the current view's metadata. 
    This is used to populate action-related UI elements with dynamic options
  */
  const actionOptions = useMemo(() => {
    const actionObj =
      !!view && !!view?.chart?.actions
        ? view?.chart?.columns
            .map((col) => col.actionOptions)
            .filter((optionsList) => !!optionsList)
            .reduce((result, current) => Object.assign(result, current), {})
        : null;

    const actionOptionsArr = !!actionObj
      ? Object.entries(actionObj)
          .map((itemArr) => ({
            label: itemArr[1],
            value: itemArr[0],
          }))
          .sort((prev, curr) => curr.value - prev.value)
      : [];
    return actionOptionsArr;
  }, [view]);

  /*
    Automatically selects the first available 
    action option as the default choice when the action options list changes.
  */
  useEffect(() => {
    setActionOption(actionOptions.length > 0 ? actionOptions[0] : null);
  }, [actionOptions]);

  /*
    This function is executed immediately to prepare DOMPurify 
    for use in sanitizing HTML content.
  */
  const domPurifyWithHooks = (domPurify) => {
    domPurify.addHook("afterSanitizeAttributes", (node) => {
      if (
        node.hasAttribute("href") &&
        node.getAttribute("href").charAt(0) !== "/" &&
        node.getAttribute("href").charAt(0) !== "#" &&
        node.getAttribute("href").charAt(0) !== ""
      ) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
      }
    });
  };

  domPurifyWithHooks(DOMPurify);

  return (
    <Layout
      pageTitle={view?.name || "Action list " + reportId}
      headerItems={
        <FavoriteButton viewId={viewId} className="p-0 my-n1 me-2" />
      }
      breadcrumbsLabel={
        <span className="ms-1 font-monospace fs-75 text-body text-opacity-75">
          <span className="opacity-75">Report ID</span> {reportId}
        </span>
      }
      path={getReportBreadcrumbs(view?.path, parentPage)}
    >
      <Stack direction="horizontal" className="align-items-start h-100">
        {isViewExpanded ? (
          <MenuOnDetail
            baseUrl={`${config.api.reporting}/views`}
            clientId={null}
            customerId={null}
            urlParams={`&chartType=actionList`}
            itemLabel="action list"
            getItemLink={({ reportId, id: viewId, chartType: viewChartType }) =>
              `/reports/${viewChartType.toLowerCase()}/${reportId}/${viewId}`
            }
            tableItems={{
              id: {
                displayHeading: "Report ID",
              },
              name: {},
            }}
            searchParam={"search"}
            orderOptions={[]}
            thumbnailCol="chartType"
            showNewItemBtn={false}
            isViewExpanded={isViewExpanded}
            setIsViewExpanded={setIsViewExpanded}
            currentView={view?.name}
          />
        ) : null}
        <WithLoaderAndError
          isLoading={viewIsValidating}
          error={fetchViewError}
          fullpage
          errorActionNavigateTo={parentPage || null}
          viewData
        >
          {dataIsValidating ? (
            <div
              key={`_${Date.now()}`}
              style={{
                opacity: viewData ? ".5" : "",
                position: "fixed",
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                background: viewData ? "#4b626b" : "",
                zIndex: 1029,
              }}
            >
              <WithLoaderAndError
                isLoading={dataIsValidating}
                error={fetchDataError}
                errorActionNavigateTo={parentPage || null}
                viewData
              />
            </div>
          ) : null}
          {viewData ? (
            <Card
              className="border-0 card-main"
              style={{ width: "100%", position: "relative" }}
            >
              <Card.Header
                className="sticky-top px-2 bg-gray-200"
                style={{ top: "var(--header-height)" }}
              >
                <HeaderALDetail
                  isViewExpanded={isViewExpanded}
                  selectedRows={selectedRows}
                  actionOption={actionOption}
                  actionOptions={actionOptions}
                  refreshViewData={refreshViewData}
                  setSelectedRows={setSelectedRows}
                  setPage={setPage}
                  showSelectCustomer={showSelectCustomer}
                  setShowSelectCustomer={setShowSelectCustomer}
                  view={view}
                  query={query}
                  setQuery={setQuery}
                  setIsViewExpanded={setIsViewExpanded}
                  setActionOption={setActionOption}
                />
              </Card.Header>
              <Card.Body
                className="p-0"
                style={{
                  overflowY: "auto",
                  flexGrow: 1,
                }}
              >
                <TableALDetail
                  view={view}
                  viewData={viewData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  actionOption={actionOption}
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                />
              </Card.Body>

              <Card.Footer className="sticky-bottom bg-gray-200 d-flex justify-content-between align-items-center">
                <PaginationALDetail
                  viewData={viewData}
                  page={page}
                  setPage={setPage}
                  dataIsValidating={dataIsValidating}
                />
              </Card.Footer>
            </Card>
          ) : null}
        </WithLoaderAndError>
      </Stack>

      <SelectCustomerDrawer
        selectedCustomerId={client?.customer?.id}
        show={showSelectCustomer}
        setShow={setShowSelectCustomer}
        onSelect={setClient}
      />
    </Layout>
  );
};

export default ALDetail;
