import React from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { BsCheck2, BsFunnelFill } from "react-icons/bs";
import { BOOL_OPTIONS } from "../../../constants";

const FuncActiveFilterSelect = ({
  functionallyActive,
  setFunctionallyActive,
}) => {
  return (
    <Dropdown as={ButtonGroup} className="filter-menu">
      <Dropdown.Toggle
        variant="outline-secondary"
        size="sm"
        active={!!functionallyActive}
        title="Functionally active"
      >
        <BsFunnelFill /> Functionally active
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {BOOL_OPTIONS.map((option) => (
          <Dropdown.Item
            as="span"
            className="cursor-pointer"
            onClick={() =>
              setFunctionallyActive((curr) => (curr === option ? "" : option))
            }
            active={functionallyActive === option}
            key={option}
          >
            <BsCheck2
              className={`${
                functionallyActive !== option ? "opacity-0" : ""
              } text-success me-2`}
            />
            {!!option ? "true" : "false"}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FuncActiveFilterSelect;
