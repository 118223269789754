import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { helpSectionCategories } from "../../content/helpSection";
import HelpSection from "../custom/HelpSection";
import Layout from "../layout/Layout";
import { Badge, Card, ListGroup } from "react-bootstrap";

const HelpSectionPage = () => {
  return (
    <Layout>
      <div className="dashboard-wrap">
        <h1>Phoenix Tool Knowledge Base</h1>
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>Knowledge base</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-3">
          <ListGroup variant="flush">
            {helpSectionCategories.map((item) => (
              <LinkContainer to={`/help/${item.id}`} key={item.id}>
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-start"
                  action
                >
                  <div className="fw-bold">{item.title}</div>
                  <Badge bg="secondary" pill>
                    5
                  </Badge>
                </ListGroup.Item>
              </LinkContainer>
            ))}
          </ListGroup>
        </Card>
        <HelpSection />
      </div>
    </Layout>
  );
};

export default HelpSectionPage;
