import React from "react";
import DetailPage from "../ui/DetailPage";
import { config } from "../../config";
import { Col, Row } from "react-bootstrap";
import FloatingInput from "../ui/FloatingInput";
import { RULES } from "../../constants";
import CheckboxInput from "../ui/CheckboxInput";

const PackageDetail = () => {
  const beforeSumbit = ({ id, ...data }) => {
    return Object.fromEntries(
      Object.entries(data)
        .filter(
          ([key, value]) =>
            !((key === "credits" || key === "price") && value === "")
        )
        .map(([key, value]) =>
          key === "sequence" && value === ""
            ? [key, null]
            : key === "active"
            ? [key, !!value]
            : [key, value]
        )
    );
  };

  const FormLayout = (props) => {
    return (
      <Row className="g-2">
        <Col>
          <Row className="g-2">
            <Col>
              <FloatingInput {...props} id="name" isRequired />
            </Col>
            <Col>
              <FloatingInput {...props} id="code" />
            </Col>
            <Col>
              <FloatingInput
                {...props}
                id="thumbnailFileId"
                className="min-width-select"
              />
            </Col>
            <Col xs={12}>
              <Row className="g-2">
                <Col>
                  <FloatingInput
                    {...props}
                    id="sequence"
                    rules={RULES.integer}
                    className="min-width-input"
                  />
                </Col>
                <Col>
                  <FloatingInput
                    {...props}
                    id="price"
                    rules={RULES.currency}
                    className="min-width-input"
                  />
                </Col>
                <Col>
                  <FloatingInput
                    {...props}
                    id="credits"
                    rules={RULES.currency}
                    className="min-width-input"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <CheckboxInput
                {...props}
                id="active"
                // helpText="Inactive package is not orderable"
                // inline
                // isRequired
                // rules={{
                //   required: {
                //     value: true,
                //     message: "This field is required",
                //   },
                // }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <FloatingInput
            {...props}
            id="description"
            as="textarea"
            className="stretch-height"
          />
        </Col>
      </Row>
    );
  };

  return (
    <DetailPage
      baseUrl={`${config.api.order}/packages`}
      path={[{ url: "/packages", title: "Packages" }]}
      itemLabel={"package"}
      formLayout={FormLayout}
      beforeSumbit={beforeSumbit}
    />
  );
};

export default PackageDetail;

// const data = {
//   id: 2788,
//   clientId: null,
//   code: "TCC",
//   name: "The cake collection",
//   description: "A collection of delicious cakes!",
//   programId: null,
//   thumbnailFileId: "",
//   price: 0.0,
//   credits: 0.0,
//   sequence: null,
//   active: true,
//   products: [],
// };
