import React from "react";

const QSIcon = ({
  fill = "currentColor",
  size = "1em",
  showTitle = true,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className="custom-icon"
      {...props}
    >
      {showTitle ? <title>QuickSight</title> : null}
      <path
        d="M37 9L20 26V12L3 29V3h34M1.746 0A1.758 1.758 0 0 0 0 1.743v36.51c.004.96.787 1.742 1.743 1.747h36.51A1.759 1.759 0 0 0 40 38.258V1.746A1.759 1.759 0 0 0 38.257 0H1.746z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default QSIcon;
