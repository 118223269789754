import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsFileEarmarkRuled, BsMailbox, BsShop, BsTruck } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { config } from "../../../../config";
import fetcher from "../../../../utils/fetcher";
import { useAuthContext } from "../../../../context/AuthContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import CustomerDetailsAddressesForm from "./CustomerDetailsAddressesForm";
import BtnWithDialog from "../../../ui/BtnWithDialog";

const CustomerDetailsAddresses = ({ customer, refetch }) => {
  const { customerId } = useParams();
  const { client, setToasts } = useAuthContext();

  const dataUrl = `${config.api.phoenix}/customers/${customerId}/addresses`;

  const [error, setError] = useState(null);

  const handleDeleteAddress = (index) => {
    fetcher({
      url: `${dataUrl}`,
      method: "PUT",
      payload: [
        ...customer?.addresses.filter((item, itemIndex) => itemIndex !== index),
      ],
      customerId: config.auth.testCustomer,
      clientId: client?.id,
    })
      .then((res) => {
        refetch();
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to delete address",
          delay: 50000,
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <>
      <Row className="g-3">
        {!!customer && !!customer?.addresses.length ? (
          customer.addresses.map((item, index) => (
            <Col key={index} md={6} lg={3}>
              <Card className="bg-dark bg-opacity-10">
                <Card.Header>
                  <small
                    className="text-muted d-block py-1"
                    style={{ marginTop: "-.6em" }}
                  >
                    <span className="fs-4">
                      {item.type === "shop" ? (
                        <BsShop />
                      ) : item.type === "delivery" ? (
                        <BsTruck />
                      ) : item.type === "postal" ? (
                        <BsMailbox />
                      ) : item.type === "invoice" ? (
                        <BsFileEarmarkRuled />
                      ) : (
                        item.type
                      )}{" "}
                    </span>
                    {item.type} address
                  </small>
                </Card.Header>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  {item.street} {item.houseNumber} {item.suffix}
                  <br />
                  {item.postalCode + ", "} {item.city}
                  <br />
                  {item.province + ", "} {item.country}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end align-items-center">
                  <CustomerDetailsAddressesForm
                    onSucces={() => refetch()}
                    addresses={customer?.addresses}
                    index={index}
                    formData={item}
                  />

                  <BtnWithDialog
                    title={"Delete this address"}
                    children={"Are you sure you want to delete this address?"}
                    variant="link"
                    onConfirm={() => handleDeleteAddress(index)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p className="text-muted mb-0">No addresses</p>
          </Col>
        )}
      </Row>
      <div className="text-right" style={{ marginTop: "0.5rem" }}>
        <CustomerDetailsAddressesForm
          onSucces={() => refetch()}
          addresses={customer?.addresses}
        />
      </div>
    </>
  );
};
export default CustomerDetailsAddresses;
