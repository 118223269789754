import React from "react";

const TextBlock = ({
  text = "",
  length = 136,
  as: Element = "div",
  lines = 2,
  ...rest
}) => {
  // trim to the last space
  const trimmedString = text.substring(0, length);
  const trimmedToSpace = trimmedString.substring(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );

  return (
    <Element
      {...rest}
      style={{
        display: "-webkit-box",
        WebkitLineClamp: lines + "",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
    >
      {trimmedToSpace}
      {text?.length > length ? <>&hellip;</> : null}
    </Element>
  );
};

export default TextBlock;
