import React, { useState } from "react";
import {
  Badge,
  Dropdown,
  DropdownItem,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import { BsChevronBarDown } from "react-icons/bs";

const DropdownSearch = ({ options, onSelect }) => {
  const [search, setSearch] = useState("");
  const onSearchInput = (e) => {
    setSearch(e.target.value);
  };
  const onItemClick = (v) => {
    onSelect(v);
    setSearch("");
  };

  const filteredOptions = options?.filter((co) => {
    const values = Object.values(co);

    return values.filter(
      (value) =>
        `${value}`
          .toLocaleLowerCase()
          .trim()
          .indexOf(search.toLocaleLowerCase().trim()) > -1
    )?.length;
  });

  return (
    <div>
      <Dropdown>
        <FormGroup className="pt-1">
          <div>
            <Dropdown.Toggle
              as={FormControl}
              value={search}
              onChange={onSearchInput}
              placeholder="Select"
              className="cursor-pointer"
            />
            <BsChevronBarDown className="position-absolute end-0 top-50 translate-middle-y me-3 opacity-75 pointer-events-none" />
          </div>
        </FormGroup>
        <Dropdown.Menu
          className="w-100 py-0"
          style={{ maxHeight: "214px", overflowY: "auto", borderRadius: "4px" }}
        >
          {options
            ? filteredOptions.map((c) => (
                <DropdownItem
                  onClick={() => onItemClick(c)}
                  key={c.id}
                  className="d-flex justify-content-between align-items-top cursor-pointer"
                  as="button"
                  type="button"
                >
                  {c.name}
                  <span className="small font-monospace">
                    <Badge className="font-monospace bg-opacity-50" bg="info">
                      {c.id}
                    </Badge>
                  </span>
                </DropdownItem>
              ))
            : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default DropdownSearch;
