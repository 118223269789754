import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, Modal } from "react-bootstrap";
import { BsCheck2, BsPerson, BsTrash, BsX } from "react-icons/bs";
import ImgFileIdInput from "../../../ui/ImgFileIdInput";
import { config } from "../../../../config";
import uploadFile from "../../../../utils/fetcherUploadFile";

const MarketingProfileSignatureItem = ({
  index,
  dirtyFields,
  setValue,
  refreshSignatures,
  formSubmit,
  watch,
  register,
  reset,
  defaultValues,
  setError,
  customerId,
  clientId,
  signatures,
}) => {
  const isEdited = (i, propName) => {
    return dirtyFields[i] && dirtyFields[i][propName];
  };

  const formProps = {
    watch,
    reset,
    register,
  };

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleOnFileUpload = (file, index) => {
    const format = file.name.split(".").pop();
    const allowedFormats = ["png", "jpg", "jpeg", "psd", "eps"];
    const fileName = file.name;
    const fileCategory = "CustomerUpload";
    const url = `${config.api.files}/Upload`;
    if (!allowedFormats.includes(format)) {
      setError(
        "Unsupported file format. Allowed formats are: PNG, JPG, JPEG, PSD, EPS."
      );
      return;
    }
    uploadFile({
      url: url,
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
      file: file,
      customerId: customerId,
      clientId: clientId,
      onSuccess: (data) => {
        setValue(`${index}.fileId`, data.fileId);
        setValue(`${index}.fileName`, fileName);
        formSubmit();
        refreshSignatures();
      },
      onError: (err) => {
        removeFile();
        setError("Failed to upload image");
      },
    });
  };

  const removeFile = () => {
    setValue(`${index}.fileId`, "");
    setValue(`${index}.fileName`, "");
    formSubmit();
  };

  const removeImage = () => {
    removeFile();
    refreshSignatures();
  };

  return (
    <Card>
      <Col className="d-flex">
        <div
          className="img-wrap-marketingprofile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="img-wrap">
            <Badge
              className="font-monospace position-absolute bottom-0 end-0 z-index-1 bg-primary-tint-50 floating-label-badge fs-70"
              bg={null}
            >
              {watch(`${index}.fileName`)}
            </Badge>
            <ImgFileIdInput
              id={`${index}.fileId`}
              formProps={formProps}
              imageContent={signatures}
              defaultValues={defaultValues}
              resizeMode="contain"
              imgBg="rgba(255,255,255,.7)"
              padding={4}
              handleOnFileUpload={(file) => handleOnFileUpload(file, index)}
            />
          </div>
          <Badge className="wrap-badge">{index + 1}</Badge>

          <div className="remove-button">
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowRemoveModal(true)}
              className="rounded-circle"
              disabled={!watch(`${index}.fileId`)}
            >
              <BsTrash color="white" />
            </Button>
          </div>
        </div>
        <div className="text-wrap-marketingprofile w-100">
          <Col className="d-flex align-items-center mb-3">
            <Form.Control
              className="form-control"
              style={
                isEdited(index, "name")
                  ? {
                      backgroundColor: "rgba(0, 255, 0, 0.2)", //light green
                    }
                  : { backgroundColor: "rgba(118, 133, 147, 0.1)" }
              }
              size="md"
              type="text"
              placeholder="Name"
              {...register(`${index}.name`)}
            />
          </Col>
          <Col className="d-flex align-items-center mb-3">
            <BsPerson style={{ marginRight: "2%" }} />
            <Form.Control
              className="form-control"
              style={
                isEdited(index, "function")
                  ? {
                      backgroundColor: "rgba(0, 255, 0, 0.2)",
                    }
                  : { backgroundColor: "rgba(118, 133, 147, 0.1)" }
              }
              size="sm"
              type="text"
              placeholder="Function"
              {...register(`${index}.function`)}
            />
            <Form.Control type="hidden" {...register(`${index}.fileId`)} />
            <Form.Control type="hidden" {...register(`${index}.fileName`)} />
          </Col>
        </div>
      </Col>
      <Modal
        style={{ marginTop: "150px" }}
        size="lg"
        show={showRemoveModal}
        onHide={() => setShowRemoveModal(false)}
      >
        <Modal.Header>
          <h4>{"Remove image"}</h4>
        </Modal.Header>
        <Modal.Body>{`Are you sure you want to remove ${watch(
          `${index}.fileName`
        )}?`}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => setShowRemoveModal(false)}
          >
            <BsX className="btn-icon me-1" /> No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              removeImage();
              setShowRemoveModal(false);
            }}
          >
            <BsCheck2 className="btn-icon me-1" /> Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default MarketingProfileSignatureItem;
