import React from "react";
import Error from "./Error";
import Loader from "./Loader";

const WithLoaderAndError = ({
  isLoading = false,
  error = null,
  fullpage = false,
  pageWithHeader = false,
  children,
  minHeight,
  errorActionNavigateTo,
  viewData = false,
}) => {
  if (isLoading) {
    return (
      <Loader
        fullPage={fullpage}
        pageWithHeader={pageWithHeader}
        minHeight={minHeight}
        viewData={viewData}
      />
    );
  }
  if (error) {
    return (
      <Error
        fullPage={fullpage}
        error={error}
        pageWithHeader={pageWithHeader}
        // minHeight={minHeight}
        navigateTo={errorActionNavigateTo}
      />
    );
  }
  return <>{children}</>;
};

export default WithLoaderAndError;
