import React from "react";
import { FormCheck, FormText } from "react-bootstrap";
import camelCaseToText from "../../utils/camelCaseToText";

const CheckboxInput = ({
  id,
  isRequired,
  label,
  formProps,
  rules = {},
  helpText,
  inline,
  type = "switch",
  ...props
}) => {
  const { register, errors, editable, isSubmitted } = formProps;
  const labelFromId = (id) => {
    return (label || camelCaseToText(id)) + (isRequired ? "*" : "");
  };

  return (
    <>
      <FormCheck
        {...props}
        {...register(id, {
          required: !!isRequired,
          ...rules,
        })}
        type={type}
        id={id}
        label={labelFromId(id)}
        isInvalid={errors?.[id]}
        isValid={isSubmitted && !errors?.[id]}
        readOnly={!editable}
        disabled={!editable}
        inline={inline}
        feedbackType="invalid"
        feedback={errors?.[id]?.message}
      />
      {helpText ? (
        <div className={inline ? "d-inline" : "form-switch"}>
          <FormText as={inline ? "span" : "div"} className="mt-n1">
            {helpText}
          </FormText>
        </div>
      ) : null}
    </>
  );
};

export default CheckboxInput;
